import React, { Component } from 'react';
import './LoginHome.css';
import { Page } from 'framework7-react';
import Logo from 'assets/logosmall.png';
import Button from '../../button/Button';
import utils from '../../../utils/utils';
import Label from '../../label/Label';
import loginController from '../../../controllers/loginController';
import SecondaryButton from '../../secondaryButton/SecondaryButton';
import offlineController from '../../../controllers/offlineController';

export const VERSION = '3.14.5';

export default class LoginHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			online: false
		};
	}
	async componentWillReceiveProps(nextProps, nextContext) {
		const online = await offlineController.isOnline();
		if (!online) {
			this.setState({ online: false });
		}
	}

	componentDidMount = async () => {
		const online = await offlineController.isOnline();
		if (!online) {
			this.setState({ online: false });
		}
	};
	renderSandboxMode = () => {
		if (!localStorage.useSandbox) return null;
		return (
			<Label small white className="sandbox-label">
				SANDBOX
			</Label>
		);
	};
	renderOfflineButton = () => {
		if (this.state.online) return null;
		return (
			<SecondaryButton
				className={'go-offline'}
				center
				onClick={() => {
					loginController.hide();
				}}
			>
				Go offline
			</SecondaryButton>
		);
	};

	render() {
		return (
			<Page className="g-login-home" name="login-home">
				{this.renderSandboxMode()}
				<img className="logo" src={Logo} onClick={utils.switchModeClick} alt="login-logo" />
				<h2>Track your meds from receipt to disposal</h2>
				<Button center className="signup-button" color="white" bg="blue" href="/signup/">
					Contact us
				</Button>
				<p>Already have an account?</p>
				<Button center color="blue" bg="white" href="/login/">
					Log-in
				</Button>
				{this.renderOfflineButton()}
				<Label className="bottom-small" center small>
					Version {VERSION}
				</Label>
			</Page>
		);
	}
}
