import objUtils from 'utils/objUtils';
import mainAPI from 'api/MainAPI';
import _ from 'lodash';
import utils from '../utils/utils';
import models from './models';

export default class GeneralModel {
	constructor(modelName, socket, allEndpoint, saveLocalKey) {
		this.model = modelName;
		this.allEndpoint = allEndpoint;
		this.socket = socket;
		this.saveLocalKey = saveLocalKey;
		this.Objects = {};
		if (saveLocalKey) this.Objects = utils.parseJSON(localStorage[saveLocalKey]) || {};
		this.ChangeHandlers = {};
		this.SingleChangeHandlers = {};
		this.CurrentChangeHandlers = {};
		this.CurrentObjId = null;
		this._GetData().then();
		this.LastSubscribeId = Date.now();
		this.tempObj = {};
	}

	onChange = (callback) => {
		let id = this.LastSubscribeId++;
		this.ChangeHandlers[id] = callback;
		if (!objUtils.is_empty(this.Objects)) {
			this.ChangeHandlers[id](_.cloneDeep(this.Objects));
		}
		return parseInt(id, 10);
	};

	onSingleChange = (id, callback) => {
		let sub_id = this.LastSubscribeId++;
		if (!this.SingleChangeHandlers[id]) this.SingleChangeHandlers[id] = {};
		this.SingleChangeHandlers[id][sub_id] = callback;
		if (this.Objects[id]) {
			this.SingleChangeHandlers[id][sub_id](_.cloneDeep(this.Objects[id]));
		}
		return parseInt(sub_id, 10);
	};

	onCurrentChange = (callback) => {
		let id = this.LastSubscribeId++;
		this.CurrentChangeHandlers[id] = callback;
		if (!objUtils.is_empty(this.Objects)) {
			this.CurrentChangeHandlers[id](this.getCurrent());
		}
		return parseInt(id, 10);
	};

	unsubscribe = (id) => {
		if (this.ChangeHandlers[id]) {
			delete this.ChangeHandlers[id];
		} else if (this.CurrentChangeHandlers[id]) {
			delete this.CurrentChangeHandlers[id];
		} else {
			for (let i in this.SingleChangeHandlers) {
				for (let j in this.SingleChangeHandlers[i]) {
					if (j === id) {
						delete this.SingleChangeHandlers[i][j];
					}
				}
			}
		}
	};

	save = async (obj, localOnly) => {
		obj = utils.client_to_server_obj(obj, this.model);
		if (localOnly) {
			this.Objects[obj.id] = obj;
			this._FireChanged(obj.id);
			return _.cloneDeep(obj);
		} else if (!obj.id) {
			let res = await mainAPI[this.model].create(obj);
			if (!res) return;
			this.Objects[res.id] = res;
			this._GetData();
			this._FireChanged(res.id);
			return _.cloneDeep(res);
		} else {
			delete obj.created_on;
			let res = await mainAPI[this.model].update(obj);
			if (!res) return;
			this.Objects[obj.id] = res;
			this._FireChanged(obj.id);
			return _.cloneDeep(res);
		}
	};

	delete = async (obj_id) => {
		//make async
		delete this.Objects[obj_id];
		let deleteSuccess = await mainAPI[this.model].delete({ id: obj_id }, function () {});
		this._FireChanged(obj_id);
		if (deleteSuccess) {
			return deleteSuccess;
		}
	};

	obj = (obj_id) => {
		return _.cloneDeep(this.Objects[obj_id]);
	};

	objs = () => {
		return _.cloneDeep(this.Objects);
	};

	search = (search, field) => {
		return objUtils.search(this.Objects, field, search);
	};

	getCurrent = () => {
		return this.Objects[this.CurrentObjId];
	};

	setCurrent = (obj) => {
		this.CurrentObjId = obj.id;
		this.Objects[obj.id] = obj;
		for (let i in this.CurrentChangeHandlers) {
			this.CurrentChangeHandlers[i](_.cloneDeep(obj));
		}
	};

	refreshData = async () => {
		this._subscribeSockets();
		await this._GetData();
	};

	_subscribeSockets = () => {
		if (this.socketSubscribeId) this.socket.off(this.socketSubscribeId);
		let user = models.user.getCurrent();
		if (!user) return;
		this.socketSubscribeId = this.socket.on(user.user_group_id, this._dataChanged);
	};

	_dataChanged = async (message) => {
		message = JSON.parse(message);
		let ids = message.data;
		let objs = [];
		for (let i in ids) {
			let item = await mainAPI[this.model].get(ids[i]);
			if (!item) continue;
			objs.push(item);
		}
		objs = objUtils.to_obj(objs, 'id');
		this._update(objs);
	};

	_update = (objs) => {
		for (let i in objs) {
			this.Objects[i] = objs[i];
		}
		this._FireChanged();
	};

	_GetData = async () => {
		let allEndpoint = mainAPI[this.model].all;
		if (this.allEndpoint) {
			allEndpoint = mainAPI[this.model][this.allEndpoint];
		}
		let objects = await allEndpoint();
		if (this.Objects && !objects) return;
		this.Objects = objUtils.to_obj(objects, 'id');
		if (this.saveLocalKey && objects) localStorage[this.saveLocalKey] = JSON.stringify(this.Objects);
		if (this.saveLocalKey && !objects) this.Objects = JSON.parse(localStorage[this.saveLocalKey] || '{}');
		this._FireChanged();
	};

	_FireChanged = (optionalId) => {
		for (let i in this.ChangeHandlers) {
			this.ChangeHandlers[i](_.cloneDeep(this.Objects));
		}
		if (optionalId) {
			for (let i in this.SingleChangeHandlers[optionalId]) {
				this.SingleChangeHandlers[optionalId][i](this.Objects[optionalId]);
			}
		}
	};

	tempCache = (obj) => {
		if (obj) {
			for (let i in obj) {
				this.tempObj[i] = obj[i];
			}
		}
		return _.cloneDeep(this.tempObj);
	};
}
