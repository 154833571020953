import React, { Component } from 'react';
import { Page } from 'framework7-react';

export class Form extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			birthDate: '2014-04-30',
			radioSelected: 1
		};
	}

	onRadioChange(value) {
		this.setState({
			...this.state,
			radioSelected: value
		});
	}

	render() {
		return <Page></Page>;
	}
}
