import React from 'react';
import { Navbar, Page } from 'framework7-react';
import './VerifyPage.css';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import DropDown from '../../dropDown/DropDown';
import mainAPI from '../../../api/MainAPI';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import models from '../../../model/models';
import toasts from '../../../utils/toasts';
import TertiaryButton from '../../tertiaryButton/TertiaryButton';
import SecondaryButton from '../../secondaryButton/SecondaryButton';
import Icon from '../../icon/Icon';
import biometricCodeController from '../../../controllers/biometricCodeController';
import { guestVerifyPageRoute } from '../guestVerifyPage/GuestVerifyPage';

export const verifyPageRoute = '/verify/general/page';
export default class VerifyPage extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = { loading: false };
	}

	_verify = async (continueWithWarning=false) => {
		let value = this.getValue();
    if(this.props.discrepancyMessage) {
      if(continueWithWarning !== true) {
        return this._warnBeforeVerify(() => this._verify(true))
      }
    }
		let authenticated = await mainAPI.user.verify_user(value);
		if (authenticated) {
			await this.onSuccess({ witness_id: value.user_id });
		} else {
			toasts.show('Incorrect password');
		}
	};

	_guestVerify = () => {
		window.f7.views.current.router.navigate(guestVerifyPageRoute, { props: { onSuccess: this.onSuccess, discrepancyMessage:this.props.discrepancyMessage } });
	};

	selfVerify = async (continueWithWarning=false) => {
    if(this.props.discrepancyMessage) {
      if(continueWithWarning !== true) {
        return this._warnBeforeVerify(() => this.selfVerify(true))
      }
    }
		await this.onSuccess({});
	};
	onSuccess = async (methodObject) => {
		if (this.state.loading) return toasts.show('loading');
		this.setState({ loading: true });
		await this.props.onSuccess(methodObject).catch((e) => {
			console.error('Verify page failure', e);
		});
		this.setState({ loading: false });
	};

	_biometricVerify = (continueWithWarning=false) => {
		if (this.state.loading) return toasts.show('loading');
    if(this.props.discrepancyMessage) {
      if(continueWithWarning !== true) {
        return this._warnBeforeVerify(() => this._biometricVerify(true))
      }
    }
		biometricCodeController.start(async (userWithBiometricGuid) => {
			await this.onSuccess(userWithBiometricGuid);
		});
	};

  _warnBeforeVerify = (callback) => {
    window.f7.dialog.confirm(
      '',
      this.props.discrepancyMessage,
      () => {
        callback()
      },
      () => {});
  };

	render() {
		let user = models.user.getCurrent() || {};
		const userGroup = models.user_group.obj() || {};
		let selfVerify = null;
		if (userGroup.allow_self_witness) {
			selfVerify = (
				<SecondaryButton disabled={this.state.loading} center onClick={this.selfVerify}>
					Self Verify
				</SecondaryButton>
			);
		}
		return (
			<Page className="g-verify-page">
				<Navbar backLink="Back" title="Verify" />
				<Content>
					<Label large white>
						Select a witness
					</Label>
					<DropDown
						not={[user.id]}
						ref={(ref) => (this.myRefs.user_id = ref)}
						placeholder="Select User"
						endpoint={mainAPI.user.all}
						label={['first_name', 'last_name']}
					></DropDown>
					<Input
						ref={(ref) => (this.myRefs.password = ref)}
						iconName="icon-lock"
						placeholder="Password"
						type="password"
					/>
					<SecondaryButton disabled={this.state.loading} center onClick={this._verify}>
						Verify
					</SecondaryButton>
					<div className="divider"></div>
					<TertiaryButton disabled={this.state.loading} center onClick={this._guestVerify}>
						Guest Verify
					</TertiaryButton>
					{selfVerify}
					<Icon size={'xxl'} name="biometric" onClick={this._biometricVerify} />
				</Content>
			</Page>
		);
	}
}
