// import 'framework7/dist/css/framework7.ios.min.css';
// import 'framework7/dist/css/framework7.ios.colors.min.css';

import 'framework7/css/framework7.min.css';
import 'framework7/css/framework7.ios.min.css';
import './css/app.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Scanner from './components/pages/scanner/Scanner.jsx';
import Framework7 from 'framework7/framework7.esm.bundle';
import Framework7React from 'framework7-react';

/* OR for Material Theme:
import 'framework7/dist/css/framework7.material.min.css'
import 'framework7/dist/css/framework7.material.colors.min.css'
*/
// import './assets/styles.css';
// import Scanner from './components/pages/Scanner.jsx';

Framework7.use(Framework7React);

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<Scanner ref={(ref) => (window.SCANNER = ref)} />, document.getElementById('scanner'));

//
// setTimeout(() => {
//    window.f7.loginScreen.open('#LoginPopup');
// })
