import React from 'react';
import { Navbar, Page } from 'framework7-react';
import './WasteDrugDetailsPage.css';
import Content from '../../helpers/content/Content';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Button from '../../button/Button';
import Label from '../../label/Label';
import mainAPI from '../../../api/MainAPI';
import models from '../../../model/models';
import DrugScanned from '../../popups/drugScanned/DrugScanned';
import toasts from '../../../utils/toasts';
import getLocation, { getLastLocation } from '../../../utils/getLocation';
import gramUtils from '../../../utils/gramUtils';
import Volume from '../../volume/Volume';
import verifyController from '../../../controllers/verifyController';
import WasteDrugPopup from '../../popups/wasteDrugPopup/WasteDrugPopup';

export const wasteDrugDetailsRoute = '/waste/details/';

export default class WasteDrugDetailsPage extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = {};
		this.myRefs = {};
		getLocation();
	}

	_waste = async (verifier) => {
		let quantityFromInput = null;
		let reverseDistributor = null;
		if (this.myRefs.quantity) {
			quantityFromInput = this.myRefs.quantity.getValue();
		}
		if (this.myRefs.reverse_distributor) {
			reverseDistributor = this.myRefs.reverse_distributor.getValue();
		}
		const success = await this.props.onComplete(
			verifier,
			this.myRefs.notes.getValue(),
			quantityFromInput,
			reverseDistributor
		);
		if (!success) return;
		window.popupController.hide(DrugScanned);
		window.popupController.hide(WasteDrugPopup);
	};

	_wasteDrug = async (verifier, drugId) => {
		let optionalProperties = {};
		if (this.myRefs.quantity) {
			const quantity = -Math.abs(this.myRefs.quantity.getValue()); // force negative
			if (quantity) {
				optionalProperties.quantity = gramUtils.toMicrograms(quantity, this.props.drugDetails.type);
			} else {
				optionalProperties.waste_all = 1;
			}
		} else {
			optionalProperties.waste_all = 1;
		}

		let location = getLastLocation();
		let stockMoveRecord = {
			stock_move_type_id__name: 'waste',
			drug_id: drugId,
			...optionalProperties,
			witness_id: verifier.witness_id,
			witness_biometric_guid: verifier.guid,
			guest_verify__license_number: verifier.guest_verify__license_number,
			guest_verify__name: verifier.guest_verify__name,
			guest_verify__signature_img: verifier.guest_verify__signature_img,
			guest_verify__title: verifier.guest_verify__title,
			notes: this.myRefs.notes.getValue(),
			lat: location.lat,
			lon: location.lon
		};
		if (this.myRefs.reverse_distributor) {
			stockMoveRecord.reverse_distributor = this.myRefs.reverse_distributor.getValue();
		}
		await mainAPI.stock_move_record.create(stockMoveRecord);
	};

	_verify = () => {
		if (!this.isValid()) return;
		if (this.myRefs.quantity) {
			const attemptedWaste = parseInt(this.myRefs.quantity.getValue());
			if (attemptedWaste === 0) {
				return toasts.show('0 is not a valid value');
			}
			if (attemptedWaste < 0) {
				return toasts.show('negative values are not valid');
			}
			if (this.props.drugDetails) {
				const totalAvailable = this.props.drugDetails?.stock_move_record__quantity;
				const attemptedWasteMCG = gramUtils.toMicrograms(attemptedWaste, this.props.drugDetails?.type);
				if (attemptedWasteMCG > totalAvailable) {
					return toasts.show(
						'cannot waste more than available (' +
							Volume.displayWithPreferredUnit(totalAvailable, this.props.drugDetails?.type) +
							')'
					);
				}
			}
		}
		if (
			this.props.drugDetails &&
			this.props.drugDetails.type &&
			!models.drug_type.isVerifyRequired(this.props.drugDetails.type)
		) {
			return this._waste({});
		}
		if (this.props.offline) {
			return verifyController.startOnlyGuestVerify(this._waste);
		}
		verifyController.start(this._waste);
	};

	renderQuantityForMultiUse = () => {
		if (this.props.offline) {
			return (
				<>
					<Label dark>Amount wasted</Label>
					<Input
						type={'number'}
						ref={(ref) => (this.myRefs.quantity = ref)}
						placeholder="Amount (multi use drugs only)"
					/>
				</>
			);
		}
		if (this.props.drugDetails) {
			const drugType = models.drug_type.obj(this.props.drugDetails.type);
			if (drugType.is_reusable) {
				return (
					<>
						<Label dark>Amount wasted</Label>
						<Input required type={'number'} ref={(ref) => (this.myRefs.quantity = ref)} placeholder="Amount" />
					</>
				);
			}
		}
	};
	renderReverseDistributor = () => {
		let userGroup = models.user_group.obj();
		if (!userGroup) return null;
		if (!userGroup.allow_reverse_distributor) return null;
		return (
			<>
				<Label dark>Name of reverse distributor?</Label>
				<Input ref={(ref) => (this.myRefs.reverse_distributor = ref)} placeholder="reverse distributor" />
			</>
		);
	};

	render() {
		return (
			<Page className="g-waste-drug-details-page">
				{this.props.noBackButton ? <Navbar title="Details" /> : <Navbar backLink="Back" title="Details" />}
				<Content>
					<Label dark>Why was this wasted?</Label>
					<Input required ref={(ref) => (this.myRefs.notes = ref)} placeholder="notes" />
					{this.renderReverseDistributor()}
					{this.renderQuantityForMultiUse()}
					<Button onClick={this._verify} center bg="darkblue" color="white">
						Continue
					</Button>
				</Content>
			</Page>
		);
	}
}
