import boxScanController from './boxScanController';
import drugScanController from './drugScanController';
import scanViewController from './scanViewController';
import { barcodeAndOfflineLookup } from '../utils/barcodeLookup';
import models from '../model/models';
import toasts from '../utils/toasts';
import offlineScanController from './offlineScanController';

let ScanController = function () {
	this.start = () => {
		models.drug_type.refreshData();
		this.showScanner();
	};
	this.showScanner = () => {
		scanViewController.showScanner(
			'Scan a Drug or Box',
			'Place the scan area on the code',
			this._handleScan,
			null,
			true
		);
	};

	this._handleScan = async (text, inputManually) => {
		const timeoutId = setTimeout(() => this.promptForOfflineMode(text, inputManually), 5000);
		let { drug, box, online } = await barcodeAndOfflineLookup(text);
		clearTimeout(timeoutId);
		if (!online) return this.promptForOfflineMode(text, inputManually);
		if (drug) {
			drugScanController.start(drug);
			await drugScanController.foundDrug(drug);
		} else if (box) {
			boxScanController.start();
			await boxScanController.foundBox(box);
		} else {
			drugScanController.start();
			await drugScanController.drugNotFound(text);
		}
	};

	this.promptForOfflineMode = (text, inputManually) => {
		return window.f7.dialog.confirm('Would you like to continue offline?', 'It looks like you are offline', () => {
			offlineScanController.start(text, inputManually);
			toasts.show('going offline');
		});
	};
};

let scanController = new ScanController();
export default scanController;
