import objUtils from '../utils/objUtils';
import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';

import WasteDrugPopup from '../components/popups/wasteDrugPopup/WasteDrugPopup';
import scanViewController from './scanViewController';
import DrugScanned from '../components/popups/drugScanned/DrugScanned';
import { wasteDrug } from './wasteController';
import {checkIfResealRequired} from "../components/pages/administerDetailsPage/AdministerDetailsPage";

let BatchWasteController = function () {
	this.drugs = [];
	this.sending = false;
	this.start = (drug) => {
		this.reset();
		this._onAddDrug(drug);
	};

	this.reset = () => {
		this.drugs = [];
		this.sending = false;
	};

	this.showScanner = () => {
		scanViewController.showScanner(
			'Scan a Drug',
			'Place the scan area on the code',
			this._handleScan,
			this._wasteDetails
		);
	};

	this._handleScan = async (text) => {
		let drug = await mainAPI.drug.find_by_barcode({ barcode_id: text });
		if (!drug) return toasts.show('No drug found with that code');
		this._onAddDrug(drug);
	};

	this._onAddDrug = (drug) => {
		if (drug.stock_move_record__quantity <= 0) {
			return toasts.show('This drug has already been used. ');
		}
		this.drugs.push(drug);
		let drugs = objUtils.to_obj(this.drugs, 'barcode_id');
		this.drugs = objUtils.to_array(drugs);
		scanViewController.setDrugs(this.drugs, 'Waste');
		this.showScanner();
	};

	this._wasteDetails = () => {
		window.popupController.hideAll();
		window.popupController.show(WasteDrugPopup, { onComplete: this._wasteDrugs });
		scanViewController.hideScanner();
	};

	this._wasteDrugs = async (verifier, notes, quantityFromInput, reverseDistributor) => {
		if (this.sending) return toasts.show('Processing');
		this.sending = true;
		let drugIds = [];
		for (let i in this.drugs) {
			drugIds.push(this.drugs[i].id);
		}
		let promises = [];
		let drugId = null;
		for (let i in drugIds) {
			drugId = drugIds[i];
			promises.push(wasteDrug(verifier, notes, null, null, drugId, reverseDistributor));
		}
		const response = await Promise.all(promises);
		const success = response.every((singleResponse) => {
			return !!singleResponse;
		});
		if (!success) {
			this.sending = false;
			mainAPI.error_log.create({
				type: 'batchWasteController Failed',
				message: 'failed to create ' + JSON.stringify(drugId) + JSON.stringify(verifier)
			});
			return toasts.show('something went wrong');
		}
		this.reset();
		window.popupController.hide(DrugScanned);
		window.popupController.hide(WasteDrugPopup);
		toasts.show('Drug wasted');
		checkIfResealRequired(drugId, verifier)
	};
};

let batchWasteController = new BatchWasteController();

export default batchWasteController;
