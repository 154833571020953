import React, { Component } from 'react';
import './InvitePopup.css';
import { Popup, View } from 'framework7-react';
import InvitePage from '../../pages/invitePage/InvitePage';

export default class InvitePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: this.props.opened
		};
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({ opened: nextProps.opened });
	};

	close = () => {
		this.setState({ opened: false });
		window.popupController.hide(InvitePopup);
	};

	render() {
		return (
			<Popup tabletFullscreen opened={this.state.opened} className="g-invite-popup" id="InvitePopup">
				<View stackPages>
					<InvitePage />
				</View>
			</Popup>
		);
	}
}
