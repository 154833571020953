import React from 'react';
import './InvitePage.css';
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from 'framework7-react';
import Input from '../../input/Input';
import FontIcon from '../../fontIcon/FontIcon';
import Button from '../../button/Button';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import mainAPI from 'api/MainAPI';

export default class InvitePage extends FormTemplate {
	send = async () => {
		let newuser = this.getValue();
		let res = await mainAPI.user.invite(newuser);
		if (res) {
			window.popupController.hideAll();
		}
	};

	render() {
		return (
			<Page className="g-invite-page">
				<Navbar>
					<NavLeft>
						<div></div>
					</NavLeft>
					<NavTitle>Invite</NavTitle>
					<NavRight>
						<Link popupClose>
							<FontIcon name="icon-close"></FontIcon>
						</Link>
					</NavRight>
				</Navbar>
				<div className="invites">
					<Input placeholder={'Email'} iconName="icon-email" ref={(ref) => (this.myRefs.primary_email = ref)} />
					<Input placeholder={'First name'} iconName="icon-person" ref={(ref) => (this.myRefs.first_name = ref)} />
					<Input placeholder={'Last name'} iconName="icon-person" ref={(ref) => (this.myRefs.last_name = ref)} />
					{/*<Button color="blue" bg="white">Add another email</Button>*/}
					<Button color="white" bg="blue" onClick={this.send}>
						Send invite
					</Button>
				</div>
			</Page>
		);
	}
}
