import React from 'react';
import { Page, Navbar } from 'framework7-react';
import './GuestVerifyPage.css';
import Content from '../../helpers/content/Content';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Input from '../../input/Input';
import DropDown from '../../dropDown/DropDown';
import Label from '../../label/Label';
import SecondaryButton from '../../secondaryButton/SecondaryButton';
import SignaturePopup from '../../popups/signaturePopup/SignaturePopup';
import _ from 'lodash';
export const guestVerifyPageRoute = '/guest/verify';
export default class GuestVerifyPage extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = {};
	}

	_finish = (continueWithWarning = false) => {
		if (!this.isAllValid()) return;
    if(this.props.discrepancyMessage) {
      if(continueWithWarning !== true) {
        return this._warnBeforeVerify(() => this._finish(true))
      }
    }
		let value = this.getFullValue();
		this.setState({ value });
		if (this.props.onSuccess) this.props.onSuccess(value);
	};
  _warnBeforeVerify = (okCallback) => {
    window.f7.dialog.confirm(
      '',
      this.props.discrepancyMessage,
      () => {
        okCallback()
      },
      () => {});
  };

	isAllValid = () => {
		if (!this.isValid()) return false;
		if (!this.state?.value?.guest_verify__signature_img) return false;
		return true;
	};
	getFullValue = () => {
		const value = this.getValue();
		value.guest_verify__signature_img = this.state?.value?.guest_verify__signature_img;
		return value;
	};

	onSignatureBoxTouch = _.debounce(() => {
		window.popupController.show(SignaturePopup, { onFinish: this.onSignatureComplete });
	}, 200);

	onSignatureComplete = (img) => {
		this.setState({ value: { ...this.state.value, guest_verify__signature_img: img } });
		window.popupController.hide(SignaturePopup, {});
	};

	render() {
		let value = this.state.value || {};
		return (
			<Page className="g-guest-verify-page">
				<Navbar title="Guest Verify" backLink="Back"></Navbar>
				<Content>
					<Label white>Name</Label>
					<Input
						placeholder="Name"
						ref={(ref) => (this.myRefs.guest_verify__name = ref)}
						required
						value={value.guest_verify__name}
					></Input>
					<Label white>Title</Label>
					<DropDown
						options={{
							Doctor: 'Doctor',
							Nurse: 'Nurse',
							Paramedic: 'Paramedic',
							EMT: 'EMT',
							Veterinarian: 'Veterinarian',
							Other: 'Other'
						}}
						placeholder="Title"
						ref={(ref) => (this.myRefs.guest_verify__title = ref)}
						required
						value={value.guest_verify__title}
					/>
					<Label white>License Number</Label>
					<Input
						placeholder="License Number"
						ref={(ref) => (this.myRefs.guest_verify__license_number = ref)}
						required
						value={value.guest_verify__license_number}
					/>
					<Label white>Signature</Label>
					<div
						className="signature-empty-box"
						onDragEnter={this.onSignatureBoxTouch}
						onTouchStart={this.onSignatureBoxTouch}
						onClick={this.onSignatureBoxTouch}
					>
						<img src={value.guest_verify__signature_img} alt={''} />
					</div>
					<SecondaryButton onClick={this._finish}>Finish</SecondaryButton>
				</Content>
			</Page>
		);
	}
}
