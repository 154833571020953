const OfflineStorageModel = function () {
	this.append = (type, value) => {
		const existingData = this.get(type);
		existingData.push(value);
		this.save(type, existingData);
	};
	this.get = (type) => {
		return JSON.parse(localStorage.getItem(type) || '[]');
	};
	this.save = (type, data) => {
		localStorage.setItem(type, JSON.stringify(data));
		this.fireChange();
	};
	this.LastSubscribeId = Date.now();
	this.ChangeHandlers = {};
	this.fireChange = () => {
		for (let i in this.ChangeHandlers) {
			this.ChangeHandlers[i]();
		}
	};
	this.onChange = (callback) => {
		let id = this.LastSubscribeId++;
		this.ChangeHandlers[id] = callback;
		return parseInt(id, 10);
	};
	this.unsubscribe = (id) => {
		delete this.ChangeHandlers[id];
	};
	this.numPending = () => {
		const dailyChecks = this.get(OfflineTypes.DailyCheck);
		const administers = this.get(OfflineTypes.Administer);
		return dailyChecks.length + administers.length;
	};
};

export const OfflineTypes = {
	DailyCheck: '__DailyCheck__',
	Administer: '__Administer__',
	Waste: '__Waste__'
};

const offlineStorageModel = new OfflineStorageModel();
export default offlineStorageModel;
