import BoxScannedPopup from '../components/popups/boxScannedPopup/BoxScannedPopup';
import scanViewController from './scanViewController';
import boxScannedPopupModel from "../components/popups/boxScannedPopup/BoxScannedPopup.model";

let ResealController = function () {
	this.foundBox = (box, drug, onSuccess, optionalWitness, isOptional) => {
    openEmptyBoxScannedPopup(box, drug,true, onSuccess, optionalWitness, isOptional)
		scanViewController.hideScanner();
	};
};

let resealController = new ResealController();

export default resealController;

export const openEmptyBoxScannedPopup = (value, lastDrugScanned,reseal, onSuccess, optionalWitness, isOptional) => {
  boxScannedPopupModel.clear()
  window.popupController.show(BoxScannedPopup, {
    value,
    lastDrugScanned,
    reseal,
    onSuccess,
    optionalWitness,
    isOptional
  });
};
