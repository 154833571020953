import React from 'react';
import './HowToDailyChecks.css';
import HowToView from '../howToView/HowToView';
import Icon from '../../../icon/Icon';

export default class HowToDailyChecks extends HowToView {
	constructor(props) {
		super(props);
		this.state = {
			message: 'Easily complete, record and verify daily med checks',
			nextPage: 'how-to-keep-track',
			activeIndex: 2
		};
	}

	classes = () => {
		return 'g-how-to-daily-checks';
	};
	content = () => {
		return (
			<div className="rectangle-box">
				<div>THIS MONTH</div>
				<div className="days-of-the-week">
					<div>S</div>
					<div>M</div>
					<div>T</div>
					<div>W</div>
					<div>T</div>
					<div>F</div>
					<div>S</div>
				</div>
				<Icon name="calendar_inside" />
			</div>
		);
	};
}
