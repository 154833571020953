import React, { Component } from 'react';
import mainAPI from '../../../api/MainAPI';
import { Popup } from 'framework7-react';
import Card from '../../card/Card';
import Label from '../../label/Label';
import Input from '../../input/Input';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import './EnterDualFactorCodePopup.css';
import toasts from '../../../utils/toasts';
import FontIcon from '../../fontIcon/FontIcon';
import { onLoginSuccess } from '../../pages/login/Login';
export default class EnterDualFactorCodePopup extends Component {
	constructor(props) {
		super(props);
		this.myRefs = {};
	}

	submit = async () => {
		const code = this.myRefs.code.getValue();
		const user = await mainAPI.user.loginWithCode({
			dual_factor_guid: this.props.dualFactorGuid,
			dual_factor_code: code
		});
		if (!user || !user.token) return toasts.show('Incorrect Code');
		onLoginSuccess(user);
		this.close();
	};

	close = () => {
		window.popupController.hide(EnterDualFactorCodePopup);
	};

	render() {
		return (
			<Popup {...this.props} show={this.props.show} className="g-enter-dual-factor-code-popup">
				<FontIcon onClick={this.close} name="icon-close" color="black" />
				<Card>
					<div>
						<Label large dark center>
							Enter code sent to your email
						</Label>
						<Label dark-gray center>
							Expires after 1 minute
						</Label>
						<Input placeholder={'code'} type={'number'} ref={(ref) => (this.myRefs.code = ref)} iconName="icon-lock" />
						<PrimaryButton onClick={this.submit}>Submit</PrimaryButton>
					</div>
				</Card>
			</Popup>
		);
	}
}
