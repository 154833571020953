import _ from 'lodash';

class BoxScannedPopupModel {
	constructor() {
		this.state = {};
	}

	clear = () => {
		this.state = {};
	};

	getAndSaveState = (newProps) => {
		return this.getState(newProps);
	};

	getState = (newProps) => {
		let props = _.cloneDeep(newProps);
		let state = _.cloneDeep(this.state || {});
		if (props && props.lastDrugScanned) {
			state.drugsChecked = state.drugsChecked || [];
			state.drugsChecked.push(newProps.lastDrugScanned.id);
		}
		if (props && props.drugsChecked) props.drugsChecked = state.drugsChecked;
		this.setState(state);
		this.setState(props);
		return _.cloneDeep(this.state);
	};

	setState = (newState) => {
		for (let i in newState) {
			this.state[i] = newState[i];
		}
		if (Array.isArray(this.state.drugsChecked)) {
			this.state.drugsChecked = _.uniq(this.state.drugsChecked);
			this.state.drugsChecked = this.state.drugsChecked.filter(this.isDrugInBox);
		}
		if (Array.isArray(this.state.drugsCheckedManually)) {
			this.state.drugsCheckedManually = _.uniq(this.state.drugsCheckedManually);
			this.state.drugsCheckedManually = this.state.drugsCheckedManually.filter(this.isDrugInBox);
		}
	};

	isDrugInBox = (drugId) => {
		if (!Array.isArray(this.state.drugs)) return false;
		const drugIsInBox = this.state.drugs.find((drug) => drug.id == drugId);
		return Boolean(drugIsInBox);
	};
}

const boxScannedPopupModel = new BoxScannedPopupModel();
export default boxScannedPopupModel;
