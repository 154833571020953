import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import './DatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import utils from '../../utils/utils';
import Validation from '../validation/Validation';

const formatValue = (datetime) => {
	if (!datetime) return '';
	if (!datetime.getMonth) return '';
	let month = (datetime.getMonth() + 1 + '').padStart(2, '0');
	let day = (datetime.getDate() + '').padStart(2, '0');
	let dtArray = [datetime.getFullYear(), month, day];
	return dtArray.join('-');
};

export default class DatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value,
			valid: true
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.uncontrolled) {
			this.setState({ value: nextProps.value });
		}
	}

	isValid = () => {
		let valid = true;
		let date = formatValue(this.state.value);
		if (!utils.is_client_date(new Date(date))) {
			valid = false;
		}
		if (this.props.required && !date) {
			valid = false;
		}
		this.setState({ valid });
		return valid;
	};

	getValue = () => {
		return formatValue(this.state.value);
	};

	onChange = (value) => {
		this.setState({ value }, () => {
			if (this.props.onChange) {
				this.props.onChange(formatValue(value));
				this.isValid();
			}
		});
	};
	onFocus = () => {
		utils.hideKeyboard();
	};

	renderInvalidText = () => {
		if (!this.state.valid) {
			return <Validation />;
		}
	};

	render() {
		return (
			<div className="g-date-picker">
				<ReactDatePicker
					onFocus={this.onFocus}
					onBlur={this.props.onBlur}
					placeholderText={this.props.placeholder}
					onChange={this.onChange}
					selected={this.state.value}
				/>

				{this.renderInvalidText()}
			</div>
		);
	}
}
