import React from 'react';
import './DrugDetailsPage.css';
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from 'framework7-react';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import FontIcon from '../../fontIcon/FontIcon';
import Button from '../../button/Button';
import DropDown from '../../dropDown/DropDown';
import mainAPI from '../../../api/MainAPI';
import gramUtils, { getDisplayUnit } from '../../../utils/gramUtils';
import models from '../../../model/models';
import DatePicker from '../../datePicker/DatePicker';
import Volume from '../../volume/Volume';
import utils from '../../../utils/utils';

export default class DrugDetailsPage extends FormTemplate {
	constructor(props) {
		super(props);
		let drug = this.props.drug || {};
		let drugBarcodes = this.props.drugBarcodes;
		const quantity = Volume.inPreferredUnit(drug.quantity, drug.type);
		this.state = {
			drug_or_box: 'drug',
			quantityPlaceholder: 'Amount',
			value: { ...drug, quantity },
			drugBarcodes
		};
	}

	submit = () => {
		this.isValid();
		if (!this.isValid()) return;
		if (this.state.drug_or_box === 'drug') {
			let drug = this.getValue();
			const quantity = gramUtils.toMicrograms(drug.quantity, drug.type) || 1;
			drug.barcode_id = this.props.drug.barcode_id;
			this.props.submitDrug({ ...drug, quantity }, this.props.drugBarcodes);
		} else {
			let drug_box = this.getValue();
			drug_box.barcode_id = this.props.drug.barcode_id;
			this.props.submitBox(drug_box, this.props.drugBarcodes);
		}
		this.setState({ submitted: true, value: this.getValue() });
	};

	_checkForFutureDate = () => {
		let value = this.getValue().expiration_date;
		if (!value) return;
		if (new Date(value).getTime() < Date.now()) {
			window.f7.dialog.alert('This date is in the past', 'Warning');
		}
	};

	_getOptions = () => {
		let value = this.state.value || {};
		if (this.state.drug_or_box === 'drug') {
			const toReturn = [];
      toReturn.push(
        <DatePicker
          required
          value={value.expiration_date}
          ref={(ref) => (this.myRefs.expiration_date = ref)}
          placeholder="Expiration date"
          onChange={this._checkForFutureDate}
        />
      );
			toReturn.push(
				<DropDown
					endpoint={mainAPI.drug_type.allowed_drugs}
					required
					value={value.type}
					ref={(ref) => (this.myRefs.type = ref)}
					onChange={this._onDrugTypeChange}
					placeholder="Type"
				/>
			);
			toReturn.push(
				<DropDown
					className="drug-box-drop-down"
					required
					value={value.drug_box_id}
					ref={(ref) => (this.myRefs.drug_box_id = ref)}
					placeholder="Drug Box"
					endpoint={mainAPI.drug_box.all}
				/>
			);
			if (!this.state?.onlyOneQuantity) {
				toReturn.push(
					<Input
						required
						value={value.quantity}
						ref={(ref) => (this.myRefs.quantity = ref)}
						placeholder={this.state.quantityPlaceholder}
						type="number"
					/>
				);
			}
			toReturn.push(
				<Input
					required
					value={value.lot_number}
					ref={(ref) => (this.myRefs.lot_number = ref)}
					placeholder="Lot number"
				/>
			);

			return toReturn;
		}
	};

	_typeChange = (value) => {
		let val = this.getValue();
		let drug_or_box = value;
		this.setState({ drug_or_box, value: val });
	};

	_onDrugTypeChange = (value) => {
		let quantityPlaceholder = `Amount (${getDisplayUnit(value)})`;
		if (getDisplayUnit(value) === 'qty') {
			quantityPlaceholder = 'Quantity';
		}
		this.setState({
			value: this.getValue(),
			onlyOneQuantity: models.drug_type.onlyOneQuantity(value),
			quantityPlaceholder
		});
	};

	render() {
		return (
			<Page className="g-drug-details-page">
				<Navbar>
					<NavLeft>
						<div></div>
					</NavLeft>
					<NavTitle>New Drug {utils.upperCase(this.props?.drug?.barcode_id || '')}</NavTitle>
					<NavRight>
						<Link popupClose>
							<FontIcon name="icon-close"></FontIcon>
						</Link>
					</NavRight>
				</Navbar>
				<div className="content">
					{this._getOptions()}
					<Button bg="blue" color="white" onClick={this.submit} icon>
						Create
					</Button>
				</div>
			</Page>
		);
	}
}
