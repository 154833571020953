import React, { Component } from 'react';
import './Label.css';

export default class Label extends Component {
	_getClasses = () => {
		return (this.props.className || '') + ' g-label ' + this._propsToClass();
	};

	_propsToClass = () => {
		let props = [
			'center',
			'justify', //text-align
			'small',
			'medium-small',
			'medium',
			'large',
			'larger',
			'largest', //size
			'italic',
			'bold',
			'pastel', //style
			'dark',
			'light',
			'dark-gray',
			'green',
			'white'
		]; //color
		let classes = [];
		for (let i in props) {
			if (this.props[props[i]]) {
				classes.push(props[i]);
			}
		}
		return classes.join(' ');
	};

	render() {
		return (
			<div onClick={this.props.onClick} className={this._getClasses()}>
				{this.props.children}
			</div>
		);
	}
}
