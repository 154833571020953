import React, { Component } from 'react';
import classNames from 'classnames';
import './FontIcon.css';

export class FontIcon extends Component {
	getSize = () => {
		let size = this.props.size;
		if (size === 'xl') {
			return 'g-xl';
		} else if (size === 'xxxxl') {
			return 'g-xxxxl';
		} else if (size === 'xxxl') {
			return 'g-xxxl';
		} else if (size === 'xxl') {
			return 'g-xxl';
		} else if (size === 'large') {
			return 'g-lg';
		} else if (size === 'medium') {
			return 'g-md';
		} else if (size === 'small') {
			return 'g-sm';
		} else if (size === 'x-small') {
			return 'g-xsm';
		} else if (size === 'xx-small') {
			return 'g-xxsm';
		} else {
			return 'g-sm';
		}
	};

	render() {
		return (
			<div
				style={this.props.style || {}}
				onClick={this.props.onClick}
				className={classNames('g-font-icon', this.props.name, this.getSize(), this.props.className, this.props.color, {
					disabled: this.props.disabled
				})}
			>
				{this.props.children}
			</div>
		);
	}
}

export default FontIcon;
