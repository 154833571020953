import scanViewController from './scanViewController';
import listenForInteraction from '../utils/listenForInteraction';

const LoginController = function () {
	this.isLoginVisible = false;
	this.show = () => {
		this.isLoginVisible = true;
		window.f7.loginScreen.open('#LoginPopup');
		scanViewController.hideScanner();
		listenForInteraction.pause();
	};
	this.hide = () => {
		this.isLoginVisible = false;
		window.f7.loginScreen.close();
	};
};

const loginController = new LoginController();

export default loginController;
