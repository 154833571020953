const ScanViewController = function () {
	this.showScanner = (title, subTitle, onSuccess, onFinish, clearState = false) => {
		window.SCANNER.showScanner(title, subTitle, onSuccess, onFinish, clearState);
	};
	this.hideScanner = () => {
		window.SCANNER.hideScanner();
	};
	this.setDrugs = (drugs, title) => {
		window.SCANNER.setDrugs(drugs, title);
	};
};
const scanViewController = new ScanViewController();

export default scanViewController;
