import scanViewController from './scanViewController';
import {
  administerDetailsPageRoute,
} from '../components/pages/administerDetailsPage/AdministerDetailsPage';
import toasts from "../utils/toasts";
import offlineStorageModel, {OfflineTypes} from "../model/offlineStorageModel";
import getLocation, {getLastLocation} from '../utils/getLocation';

/**
 * Controller for offline batch drug administration.
 * @constructor
 */
function OfflineBatchAdministerController() {
  const drugBarcodes = new Set();
  let administrations = [];
  let incident_number = '';

  this.start = function(scanResult = {}) {
    getLocation().then((location = {}) => {
      const { lat, lon } = location;
      const result = !!lat && !!lon ? 'succeeded in caching last location.' : 'failed to cache last location.';
      console.debug(`OfflineBatchAdministerController ${result}`)
    });
    scanViewController.hideScanner();
    const {text: barcodeId, inputManually} = scanResult;
    navigateToAdministerDetailsPage({barcodeId, inputManually});
  }
  /**
   * Adds an administration to the controller and optionally shows the scanner.
   *
   * @param {Object} administration - An object containing administration details.
   * @param {string} administration.barcodeId - The barcode ID associated with the administration.
   * @param {string} administration.incident_number - The incident number associated with the administration.
   * @param {Object} options - An object containing additional options.
   * @param {boolean} options.showScanner - Whether to show the scanner after adding the administration.
   */
  this.addAdministration = function(administration = {barcodeId: null, incident_number: null}, options = {showScanner: true}) {
    add(administration);
    this.debug();
    const {showScanner: scanner} = options;
    if (scanner) showScanner();
  }

  /**
   * Enqueues administrations for processing and resets the controller.
   *
   * @param {Object} options - An object containing options for processing administrations.
   * @param {Object} options.verifier - The verifier object for processing administrations.
   */
  this.enqueue = function({verifier} = {}) {
    administrations.map(setIncidentNumber).forEach((administration) => {
      // Map saved form data to an offline administration request.
      const {barcodeId: barcode_id, quantity, inputManually, waste_remaining} = administration;
      const {guest_verify__name, guest_verify__signature_img, guest_verify__license_number, guest_verify__title} = verifier;
      const  {lat, lon} = getLastLocation();

      // An Offline administration has to have this format.
      // See: OfflineSyncController.createAdminister which maps offlineStorageModel request to API request.
      const offlineAdministration = {
        barcode_id,
        quantity,
        guest_verify__name,
        guest_verify__signature_img,
        guest_verify__license_number,
        guest_verify__title,
        incident_number,
        input_barcode_manually: inputManually,
        wasteRemaining: waste_remaining,
        time: Date.now(),
        lat,
        lon
      };

      offlineStorageModel.append(OfflineTypes.Administer, offlineAdministration);
    });

    const count = administrations.length
    if (count == 1) {
      toasts.show('Administration will be recorded when you are online again');
    }
    else if (count > 1) {
      toasts.show(`${count} Administrations will be recorded when you are online again`);
    }
    reset();
  }

  /**
   * Displays debugging information about the controller's state.
   */
  this.debug = function () {
    console.log(`[OfflineBatchAdministerController]`)
    console.log('\tdrugBarcodes', drugBarcodes);
    console.log('\tadministrations', administrations);
    console.log('\tincident_number', incident_number);
  }

  function navigateToAdministerDetailsPage({barcodeId, quantity, waste_remaining, inputManually} = {}) {
    // Sometimes this function will get called with only a barcodeId and inputManually after performing a new scan.
    // Other times this function might need to be called with all the administration details when navigating back.
    // Currently, the "Back" functionality is taken care of by f7 router history.
    const page_options = {
      props: {
        value: {barcodeId},
        offline: true,
        incident_number,
        quantity,
        waste_remaining,
        inputManually,
        drugBarcodes: barcodes({barcodeId}),
        onBackPress: () => {
          remove({barcodeId}); // remove the current drug from the queue.
          if(drugBarcodes.size > 0) removePrevious(); // display the previous drug, and remove it form queue.
          else reset(); // User has backed out of the administrator details page completely.
        }
      }
    };
    return window.f7.views.current.router.navigate(`${administerDetailsPageRoute}/${barcodeId}`, page_options);
  };

  function add(values = {barcodeId: null, incident_number: null}){
    const {barcodeId, incident_number: number} = values;
    if (barcodeId && number) {
      incident_number = number;
      if (!drugBarcodes.has(barcodeId)) {
        drugBarcodes.add(barcodeId);
        administrations.push(values);
        return {success: 'created'};
      }
      const {updated} = update(values);
      if (updated) return {success: 'updated'};
      return {error: "Could not update administration from barcode."};
    }
    return {error: "Invalid barcode or incident number."};
  }

  function showScanner() {
    scanViewController.showScanner('Scan a Drug', 'Place the scan area on the code', (text, inputManually) => {
      scanViewController.hideScanner();
      navigateToAdministerDetailsPage({barcodeId: text, inputManually});
    },null, true);
  }

  function setIncidentNumber(administration) {
    administration.incident_number = incident_number;
    return administration;
  }

  function reset() {
    drugBarcodes.clear();
    administrations = [];
    incident_number = null;
  }

  function removePrevious() {
    return remove(administrations[administrations.length - 1]);
  }

  function remove({barcodeId} = {}) {
    if (drugBarcodes.delete(barcodeId)) {
      const updated = administrations.filter(({barcodeId: id}) => id !== barcodeId);
      if (updated.length === administrations.length) {
        // if we did not remove anything from the administrations array, then add the barcodeId back to the set.
        drugBarcodes.add(barcodeId);
        console.error('The drug barcodes and administrations are out of sync.');
      }
      else {
        administrations = updated;
        return true;
      }
    }
    return false;
  }

  function update(administration = {}) {
    const {barcodeId} = administration;
    let result = false;
    const updated = administrations.map((original) => {
      const {barcodeId: target} = original;
      if (target === barcodeId) {
        result = true;
        return administration;
      }
      return original;
    });
    administrations = updated;
    return {updated: result};
  }

  /**
   * Retrieves an array of barcode IDs in reverse order, optionally including a provided barcode ID at the front.
   *
   * @param {Object} options - An object containing options for retrieving barcode IDs.
   * @param {string} options.barcodeId - An optional barcode ID to include in the result.
   * @returns {string[]} - An array of barcode IDs, possibly including the provided barcode ID.
   */
  function barcodes({barcodeId} = {}){
    if (drugBarcodes.size === 0) {
      const result = (barcodeId) ? [barcodeId]: [];
      return result;
    }
    const result = Array.from(drugBarcodes);
    result.reverse();
    if(barcodeId && !drugBarcodes.has(barcodeId)) result.unshift(barcodeId);
    return result;
  }
};

const controller = new OfflineBatchAdministerController();

export default controller;
