import './UnAdministerMultiUsePage.css';
import Card from '../../card/Card';
import CheckBox from "../../checkBox/CheckBox";
import Content from '../../helpers/content/Content';
import DrugScanned from '../../popups/drugScanned/DrugScanned';
import Label from '../../label/Label';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import React, { useState, useEffect } from 'react';
import SecondaryButton from "../../secondaryButton/SecondaryButton";
import Volume from '../../volume/Volume';
import mainAPI from '../../../api/MainAPI';
import models from '../../../model/models';
import toasts from '../../../utils/toasts';
import utils from '../../../utils/utils.js';
import { Page, Navbar } from 'framework7-react';
import getLocation, {getLastLocation} from "../../../utils/getLocation";
import _ from "lodash";

export const unAdministerMultiUsePageRoute = {name: 'un-administer', path: '/un-administer/v1/multi-use'};

const UnAdministerMultiUsePage = ({id: drug_id, drugCode} = {}) => {
	// List of stock move records for the drug
	const [records, setRecords] = useState([]);
	// List of selected objects [{id: 1, selected: true}, {id: 2, selected: false}, ...]
	const [selected, setSelected] = useState([]);

	const isSelected = ({id: target}) => {
		const record = selected.find(({id}) => id === target)
		if (record && typeof record.selected === 'boolean') return record.selected;
		return false
	}

	useEffect(() => {
		const fetchRecords = async () => {
      if(!drug_id) return;
			try {
				const result = await mainAPI.stock_move_record.administeredForDrug(drug_id, {includeWasted: true}) || [];
				const updateSelected = result
					// Filter out records that have already been un-administered
					.filter(({un_administer_id}) => !!!un_administer_id)
					.map(({ id }) => ({id, selected: false}));
				setSelected(updateSelected);
				setRecords(result);
			} catch (error) {
				console.error('Error fetching stock move records and setting state.', error)
			}
		};
    getLocation()
		fetchRecords()
	}, [drug_id]);

	const select = (id, value) => {
		const copy = [...selected];
		const record = copy.find(({id: target}) => id === target);
		if (record) {
			record.selected = value;
			setSelected(copy);
		}
	}
	const renderRecord = ({id, created_on, type, quantity, created_by, witnessed_by, guest_verify_name, location_name, incident_number, un_administer_id, stock_move_type_name} = {}) => {
		const date = utils.display_datetime(created_on);
		const isUnAdministered = !!un_administer_id;
		const isAdminister = stock_move_type_name === 'administer';
		const undoLabel = isAdminister ? 'UN ADMINISTER' : 'UN WASTE';
		const undoneLabel = `Previously Un ${(isAdminister ? 'Administered' : 'Wasted')}`;
		return (
			<Card>
				<div className="i-row">
					<Label className="label">AMOUNT</Label>
					<Label dark bold><Volume type={type} value={Math.abs(quantity)} /></Label>
				</div>
				<div className="i-row">
					<Label className="label">USER</Label>
					<Label dark bold>{created_by}</Label>
				</div>
				<div className="i-row">
					<Label className="label">WITNESS</Label>
					<Label dark bold>{witnessed_by || guest_verify_name}</Label>
				</div>
				<div className="i-row">
					<Label className="label">DATE</Label>
					<Label dark bold>{date}</Label>
				</div>
				<div className="i-row">
					<Label className="label">INCIDENT</Label>
					<Label dark bold>{incident_number}</Label>
				</div>
				<div className="i-row location">
					<Label dark bold>{location_name}</Label>
				</div>
				<div className="i-row un-administer">
					<Label className="label">{undoLabel}</Label>
					{isUnAdministered ?
						<Label dark bold>{undoneLabel}</Label> :
						<CheckBox value={isSelected({id})}
								  onChange={(value) => select(id, value)}/>
					}
				</div>
			</Card>
		);
	}

	const unAdminister = _.debounce(() => {
		const ids = selected
			.filter(({selected}) => selected)
			.map(({id}) => id);

		const candidates = records.filter(({ id }) => ids.includes(id));

		Promise.all(candidates.map(unAdministerRecord)).then((results) => {
			if (results.some(({error}) => !!error)) {
				toasts.show('There was an error reversing the selected records.');
			}
			else {
				models.drug.refreshData();
				window.popupController.hide(DrugScanned);
			}
		}).catch((e) => {
			toasts.show('There was an error reversing the selected records.');
		});
	},1000);

	const unAdministerRecord = async ({id: source_id, quantity}) => {
		const {lat, lon} = getLastLocation();
		const data = {
			stock_move_type_id__name: 'un_administer',
			drug_id: drug_id,
			doses: 0,
			quantity: Math.abs(quantity),
			source_id,
			lat,
			lon
		};
		const result = await mainAPI.stock_move_record.create(data);
		if (!result) {
			mainAPI.error_log.create({
				type: 'UnAdministerMultiUsePage Failed',
				message: `Failed to create un-administer record. ${JSON.stringify(data)}`
			});
			return {error: `Failed to create un-administer record. source_id: ${source_id}, drug_id: ${drug_id}, quantity: ${quantity}`}
		}
		return result
	}

	const cancelButton = () => (
		<SecondaryButton center onClick={() => {
			window.f7.views.current.router.back();
		}}>
			Cancel
		</SecondaryButton>
	)

	return (
		<Page className="g-un-administer-multi-use-page">
			<Navbar backLink="Back" title={drugCode} />
			<Content>
				{records.length ? (
					<>
						{records.map(renderRecord)}
						<PrimaryButton center onClick={unAdminister}>Reverse Selected Records</PrimaryButton>
						{cancelButton()}
					</>) : (
					<>
						<Label large dark center>No Administrations</Label>
						{cancelButton()}
					</>
				)}
			</Content>
		</Page>
	);
}
export default UnAdministerMultiUsePage;
