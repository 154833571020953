import React, { Component } from 'react';
import './DrugCheckConfirmationPopup.css';
import { Block, List, ListItem, Page, Popup, View } from 'framework7-react';
import FontIcon from '../../fontIcon/FontIcon';
import Card from '../../card/Card';
import Vile from 'assets/vile_image.png';
import Button from '../../button/Button';
import utils from 'utils/utils';
import Volume from '../../volume/Volume';
import BoxScannedPopupModel from '../boxScannedPopup/BoxScannedPopup.model';
import inventoryCheckController from '../../../controllers/inventoryCheckController';
import models from '../../../model/models';
import boxScannedPopupModel from '../boxScannedPopup/BoxScannedPopup.model';
import _ from 'lodash';

let Navbar = (props) => {
	return (
		<div className="g-navbar">
			<div></div>
			<div>Scan a Drug</div>
			<FontIcon onClick={props.cancel} name="icon-close" color="white" />
		</div>
	);
};

class DrugCheckConfirmationPopup extends Component {
	continue = async () => {
		window.popupController.hide(DrugCheckConfirmationPopup);
		inventoryCheckController.continue(this.props.value);
	};

	_scanAnother = async () => {
		window.popupController.hide(DrugCheckConfirmationPopup);
		BoxScannedPopupModel.getState({ lastDrugScanned: this.props.value });
		this.props.onConfirm();
	};

	cancel = () => {
		window.popupController.hide(DrugCheckConfirmationPopup);
		this.props.onCancel();
	};

	inventoryProgress = () => {
		const drugs = boxScannedPopupModel.state.drugs || [];
		const totalInBox = drugs.length || 0;
		const drugsChecked = boxScannedPopupModel.state.drugsChecked || [];
		const drugsCheckedManually = boxScannedPopupModel.state.drugsCheckedManually || [];
		const allChecked = _.uniq(drugsChecked.concat(drugsCheckedManually).concat([this?.props?.value?.id]));

		return `${allChecked.length} of ${totalInBox}`;
	};

	render() {
		let value = this.props.value || {};
		let drugTypeName = models.drug_type.obj(value.type);
		let drugName = drugTypeName.name;

		return (
			<Popup tabletFullscreen opened={this.props.opened} className="g-drug-check-confirmation-popup">
				<View stackPages>
					<Page className="drug-check-confirmation-popup-page">
						<Navbar cancel={this.cancel} />
						<Block>
							<div className="success">
								<FontIcon name="icon-done-all" />
								<p>Scanned successfully</p>
								<div></div>
							</div>
							<div className="info">
								<img className="vile" src={Vile} alt={'vile'} />
								<Card>
									<List>
										<ListItem>
											<p>DRUG</p>
											<p>{drugName}</p>
										</ListItem>
										<ListItem>
											<p>EXPIRATION</p>
											<p>{utils.display_date(value.expiration_date)}</p>
										</ListItem>
										<ListItem>
											<p>AMOUNT</p>
											<p>
												<Volume type={value.type} value={value.stock_move_record__quantity} />
											</p>
										</ListItem>
										<ListItem>
											<p>CODE</p>
											<p>{utils.upperCase(value.barcode_id)}</p>
										</ListItem>
									</List>
								</Card>
							</div>
							<div className="progress">{this.inventoryProgress()}</div>
							<Button bg="blue" color="white" center className="confirm" onClick={this.continue}>
								Confirm
							</Button>
							<Button bg="darkblue" color="white" center className="scan-another" onClick={this._scanAnother}>
								Scan Another
							</Button>
						</Block>
					</Page>
				</View>
			</Popup>
		);
	}
}

export default DrugCheckConfirmationPopup;
