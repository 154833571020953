import mainAPI from '../api/MainAPI';
import models from '../model/models';
import BoxScannedPopup from '../components/popups/boxScannedPopup/BoxScannedPopup';
import DrugCheckConfirmationPopup from '../components/popups/drugCheckConfirmationPopup/DrugCheckConfirmationPopup';
import toasts from 'utils/toasts.js';
import getLocation from '../utils/getLocation';
import boxScannedPopupModel from '../components/popups/boxScannedPopup/BoxScannedPopup.model';
import scanViewController from './scanViewController';
import offlineInventoryCheckController from './offlineInventoryCheckController';
import { barcodeAndOfflineLookup } from '../utils/barcodeLookup';
import {openEmptyBoxScannedPopup} from "./resealController";

let InventoryCheckController = function () {
	this.start = () => {
		this.clear();
		scanViewController.showScanner('Inventory Check', 'Scan a box or a drug in the box', this._onScanText, null, true);
		getLocation();
	};

	this.clear = () => {
		boxScannedPopupModel.clear();
	};

	this.foundBox = (box, drug) => {
    openEmptyBoxScannedPopup(box, drug, false)
		scanViewController.hideScanner();
		return { value: box, lastDrugScanned: drug };
	};

	this._onScanText = async (text, inputManually) => {
		const timeoutId = setTimeout(() => this.promptForOfflineMode(text, inputManually), 5000);
		let { drug, box, online } = await barcodeAndOfflineLookup(text);
		clearTimeout(timeoutId);
		if (!online) return this.promptForOfflineMode(text, inputManually);
		if (drug && !box) {
			box = models.drug_box.obj(drug.drug_box_id); // use main api
		}
		if (box) {
			this.foundBox(box, drug);
		} else {
			toasts.show('Box not found');
		}
	};

	this.promptForOfflineMode = (scanText) => {
		return window.f7.dialog.confirm(
			'We can save the inventory check when you are back online',
			'Would you like to continue offline?',
			() => {
				scanViewController.hideScanner();
				offlineInventoryCheckController.start(scanText);
				toasts.show('going offline');
				this.clear();
			}
		);
	};

	this._onDrugScan = async (text) => {
		let drug = await mainAPI.drug.find_by_barcode({ barcode_id: text });
		drug = await mainAPI.drug.get(drug?.id);
		if (drug) {
			if (boxScannedPopupModel.isDrugInBox(drug?.id)) {
				window.popupController.show(DrugCheckConfirmationPopup, {
					value: drug,
					onConfirm: this.scanForDrugInBox,
					onCancel: this.scanForDrugInBox
				});
				scanViewController.hideScanner();
			} else {
				toasts.show(`Drug (${text}) not in box`);
			}
		} else {
			toasts.show('Drug not found');
		}
	};

	this.continue = (drug) => {
		window.popupController.show(BoxScannedPopup, { lastDrugScanned: drug });
	};

	this.scanForDrugInBox = () => {
		window.popupController.hideAll();
		scanViewController.showScanner('Inventory Check', 'Scan a drug in this box', this._onDrugScan);
	};
};

let inventoryCheckController = new InventoryCheckController();
export default inventoryCheckController;
