import scanViewController from './scanViewController';
import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';

const BiometricCodeController = function () {
	this.start = (onSuccess) => {
		this.onSuccess = onSuccess;
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner(
			'Scan Biometric Code',
			'Place the scan area on the code',
			this._handleScan,
			null,
			true
		);
	};

	this._handleScan = async (biometric_guid) => {
		const res = await mainAPI.biometric_code.get_by_guid(biometric_guid);
		scanViewController.hideScanner();
		if (!res) return toasts.show('Unknown Error.');
		const {access_to_group, is_biometric_code, is_expired, is_owner} = res;
		if (!is_biometric_code) return toasts.show('Invalid code. Ensure you are scanning another User\'s biometric code.', 5_000);
		if (is_expired) return toasts.show('This code has expired.');
		if (is_owner) return toasts.show('You cannot scan your own code.');
		if (!access_to_group) return toasts.show('This user is not part of your agency.');
		if (this.onSuccess) this.onSuccess(res);
	};
};
const biometricCodeController = new BiometricCodeController();

export default biometricCodeController;
