import GeneralModel from './GeneralModel';
import SocketModel from './SocketModel';
import UserGroupModel from './UserGroupModel';
import DrugTypeModel from './DrugTypeModel';

export let sockets = {};
sockets.user = new SocketModel('user');
sockets.drug = new SocketModel('drug');
sockets.drug_box = new SocketModel('drug_box');
sockets.drug_type = new SocketModel('drug_type');
sockets.unit = new SocketModel('unit');
sockets.user_group = new SocketModel('user_group');
sockets.stock_move_record = new SocketModel('stock_move_record');

let models = {};

models.drug = new GeneralModel('drug', sockets.drug);

models.drug_box = new GeneralModel('drug_box', sockets.drug_box);
models.user = new GeneralModel('user', sockets.user);
models.user_group = new UserGroupModel('user_group', sockets.user_group, 'current', '__current_user_group__');
models.drug_type = new DrugTypeModel('drug_type', sockets.drug_type, 'all_with_allowed');

window.models = models;

export const getCurrentUser = () => {
 return models.user.getCurrent();
}

export default models;
