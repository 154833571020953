import React, { Component } from 'react';
import { Page, Navbar } from 'framework7-react';
import './CodeAuthenticationPage.css';
import bwipjs from 'bwip-js';
import Label from '../../label/Label';
import Content from '../../helpers/content/Content';
import mainAPI from '../../../api/MainAPI';
import toasts from '../../../utils/toasts';

export default class CodeAuthenticationPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			biometric_guid: null
		};
	}

	componentDidMount = async () => {
		await this.setCode();
	};

	setCode = async () => {
		const res = await mainAPI.biometric_code.create({});
		if (!res) return toasts.show('Could not create biometric code.');
		this.setState({ biometric_guid: res.guid }, () => {
			bwipjs.toCanvas('CodeCanvas', {
				bcid: 'datamatrix', // Barcode type
				text: res.guid, // Text to encode
				scale: 6.5,
				height: 17, // Bar height, in millimeters
				includetext: false, // Show human-readable text
				textxalign: 'center', // Always good to set this
				backgroundcolor: 'FFFFFF'
			});
		});
	};

	render() {
		return (
			<Page className="g-code-authentication-page">
				<Navbar title="Verify Code" />
				<Content>
					<Label center large dark>
						Scan this code on another device to verify usage or inventory checks
					</Label>
					<Label center dark>
						Code expires in {this.props.secondsUntilClosed} seconds. Only show if you witnessed the action taken.
					</Label>
					<canvas id="CodeCanvas" />
				</Content>
			</Page>
		);
	}
}
