import React, { Component } from 'react';
import './DashBoardPage.css';
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from 'framework7-react';
import FontIcon from '../../fontIcon/FontIcon';
import DropDown from '../../dropDown/DropDown';
import InventoryCard from './inventoryCard/InventoryCard';
import models, { sockets } from '../../../model/models';
import mainAPI from '../../../api/MainAPI';
import utils from '../../../utils/utils';
import ScanToolbar from '../../toolbars/scanToolbar/ScanToolbar';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import PullToRefresh from '../../pullToRefresh/PullToRefresh';
import Volume from '../../volume/Volume';
import { getDisplayUnit } from '../../../utils/gramUtils';
import offlineSyncController from '../../../controllers/offlineSyncController';

export const DashBoardPageRoute = {name: 'home', path: '/'};
export default class DashBoardPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			drugs: [],
			lastCheck: ''
		};
		this.myRefs = {};
	}

	componentDidMount = () => {
		this.drugSubscribeId = models.drug.onChange(this._drugsChanged);
		this.userSubscribeId = models.user.onCurrentChange(this._userChanged);
	};

	componentWillUnmount = () => {
		models.drug.unsubscribe(this.drugSubscribeId);
		models.user.unsubscribe(this.userSubscribeId);
		if (this.socketSubscribeId) sockets.stock_move_record.off(this.socketSubscribeId);
	};

	_refresh = async () => {
    const promises = [
		 models.user.refreshData(),
		 models.user_group.refreshData(),
		 models.drug.refreshData(),
		 models.drug_box.refreshData()
    ];
    await Promise.all(promises);
		await offlineSyncController.start().catch(console.error);
		this.pullToRefresh.finish();
	};

	_listenForStockMovement = () => {
		let user = models.user.getCurrent();
		if (!user) return;
		if (this.socketSubscribeId) return;
		this.socketSubscribeId = sockets.stock_move_record.on(user.user_group_id, this._refreshDrugs);
	};

	_userChanged = (user) => {
		if (user?.id) offlineSyncController.start().catch(console.error);
		this._boxCheckChanged();
		this._listenForStockMovement();
	};

	_boxCheckChanged = async () => {
		let user = models.user.getCurrent();
		if (!user || !user.id) return;
		let { lastUsedBox, lastCheck } = await this._getBoxDetails(null);
		this.setState({ lastUsedBox, lastCheck });
	};

	_refreshDrugs = async () => {
		await models.drug.refreshData();
		this._drugsChanged();
	};

	_drugsChanged = async () => {
		let drugs = models.drug.objs();
		let { lastUsedBox, lastCheck } = await this._getBoxDetails(localStorage.lastBoxId);
		this.setState({ lastCheck, drugs, lastUsedBox });
	};

	_lastCheck = async (drug_box_id) => {
		let lastCheckedDrug = await mainAPI.drug_check.last_check_for_box(drug_box_id);
		if (lastCheckedDrug) {
			return [
				<FontIcon name="icon-check" color="light-green" key="icon" />,
				<div key="last-checked">
					Last verified by {lastCheckedDrug.user__first_name + ' ' + lastCheckedDrug.user__last_name}{' '}
					{utils.display_datetime(lastCheckedDrug.created_on)}
				</div>
			];
		}
		return '';
	};

	_drugCount = (type) => {
		let count = 0;
		let lastUsedBox = this.state.lastUsedBox || {};
		for (let i in this.state.drugs) {
			let drug = this.state.drugs[i];
			if (type === drug.type && drug.stock_move_record__quantity > 0 && drug.drug_box_id == lastUsedBox.id) count++;
		}
		return count;
	};

	_totalDrugAmount = (type) => {
		let totalMcg = 0;
		let lastUsedBox = this.state.lastUsedBox || {};
		for (let i in this.state.drugs) {
			let drug = this.state.drugs[i];
			if (type === drug.type && drug.drug_box_id == lastUsedBox.id) {
				totalMcg += parseFloat(this.state.drugs[i].stock_move_record__quantity || 0);
			}
		}
		return Volume.inPreferredUnit(totalMcg, type);
	};

	_getCard = (type, i) => {
		let count = this._drugCount(type);
		const total = this._totalDrugAmount(type);
		let displayUnit = getDisplayUnit(type);
		const drugType = models.drug_type.obj(type);
		if (count == 0) return null;
		return (
			<InventoryCard count={count} total={total} displayUnit={displayUnit} drug={drugType.name} key={`inventory-card-${i}`} />
		);
	};

	_displayCards = () => {
		let types = models.drug_type.allowed();
		let cards = [];
		for (let i in types) {
			let card = this._getCard(types[i].id, i);
			if (card !== null) cards.push(card);
		}
		if (cards.length == 0) {
			return <div className="empty-box">This box is empty</div>;
		}
		return cards;
	};

	getBoxById = async (boxId) => {
		if (!boxId) return null;
		return await mainAPI.drug_box.get(boxId);
	};

	_getBoxDetails = async (boxId) => {
		let lastUsedBox = await this.getBoxById(boxId);
		if (!lastUsedBox && !boxId && !localStorage.lastBoxId) {
			const boxes = models.drug_box.objs();
			for (let i in boxes) {
				lastUsedBox = boxes[i];
				break;
			}
		}
		if (!lastUsedBox) return {};
		let lastCheck = await this._lastCheck(lastUsedBox.id);
		return { lastUsedBox, lastCheck };
	};

	_boxChanged = async (boxId) => {
		if (boxId) localStorage.lastBoxId = boxId;
		let { lastUsedBox, lastCheck } = await this._getBoxDetails(boxId);
		this.setState({ lastUsedBox, lastCheck });
	};

	_renderSandboxMode = () => {
		if (!localStorage.useSandbox) return null;
		return (
			<Label small white className="sandbox-label">
				SANDBOX
			</Label>
		);
	};

	_scrollingContainerElement = () => {
		return document.querySelector('.g-dash-board-page .page-content');
	};

	render() {
		let lastUsedBox = this.state.lastUsedBox || {};

		return (
			<Page className="g-dash-board-page">
				<Navbar>
					<NavLeft>
						<Link color="white" panelOpen="left">
							<FontIcon
								name="icon-menu"
								style={{
									transform: 'scale(1.6)',
									height: '36px'
								}}
							/>
						</Link>
					</NavLeft>
					<NavTitle sliding>
						<DropDown
							onOpen={models.drug_box.refreshData}
							subscribeTo="drug_box"
							className="g-navbar-box-select"
							onChange={this._boxChanged}
							required
							value={lastUsedBox.id}
							ref={(ref) => (this.myRefs.drug_box_id = ref)}
							placeholder="Drug Box"
							endpoint={mainAPI.drug_box.all_with_unit}
						/>
					</NavTitle>
					<NavRight></NavRight>
				</Navbar>
				<PullToRefresh
					ref={(ref) => (this.pullToRefresh = ref)}
					parent={this._scrollingContainerElement()}
					onPullToRefresh={this._refresh}
				>
					<Content className="current-inventory-row ">
						<h3>Current Inventory</h3>
						<div className="last-check">Box Code: {utils.upperCase(lastUsedBox.barcode_id)}</div>
						<div className="last-check">{this.state.lastCheck}</div>
						{this._displayCards()}
					</Content>
				</PullToRefresh>
				<ScanToolbar />
			</Page>
		);
	}
}
