import React from 'react';
import './HowToStatus.css';
import HowToView from '../howToView/HowToView';
import Content from '../../../helpers/content/Content';
import Icon from '../../../icon/Icon';

export default class HowToStatus extends HowToView {
	constructor(props) {
		super(props);
		this.state = {
			message: 'See the status of your drug box at any time',
			nextPage: 'how-to-reports',
			activeIndex: 4
		};
	}

	classes = () => {
		return 'g-how-to-status';
	};
	content = () => {
		return (
			<Content>
				<div className="title">MORPHINE</div>
				<div className="vial-row">
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
				</div>
				<div className="title">VERSED</div>
				<div className="vial-row">
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
				</div>
				<div className="title">FENTANYL</div>
				<div className="vial-row">
					<Icon name="vial-fill-white" />
					<Icon name="vial-fill-white" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
					<Icon name="vial-unfil-gray" />
				</div>
			</Content>
		);
	};
}
