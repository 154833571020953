import React, { Component } from 'react';
import './LoginPopup.css';
import { LoginScreen, View } from 'framework7-react';
import LoginHome from 'components/pages/loginHome/LoginHome';
import mainAPI from '../../../api/MainAPI';
import models from '../../../model/models';
import loginController from '../../../controllers/loginController';

export default class LoginPopup extends Component {
	constructor(props) {
		super(props);
		this.state = { lastOpen: Date.now() };
	}
	componentDidMount = async () => {
		if (localStorage.token) {
			let user = await mainAPI.user.get();
			if (!user) {
				loginController.show();
				return;
			}
			models.user.setCurrent(user);
		}
	};

	forgotPassword = () => {};

	signUp = () => {};

	continue = () => {};

	render() {
		return (
			<LoginScreen
				onLoginScreenOpened={() => {
					this.setState({ lastOpen: Date.now() });
				}}
				id="LoginPopup"
			>
				<View name="login" stackPages>
					<LoginHome lastOpen={this.state.lastOpen} />
				</View>
			</LoginScreen>
		);
	}
}
