import React, { Component } from 'react';
import $ from 'jquery';
import './Scanner.css';
import FontIcon from 'components/fontIcon/FontIcon';
import models from '../../../model/models';
import toasts from '../../../utils/toasts';
import scannerUtils from '../../../utils/scannerUtils';
import BoxActionsPopup from '../../popups/boxActionsPopup/BoxActionsPopup';
import Instascan from 'instascan';
import utils from '../../../utils/utils';
import Button from "../../button/Button";

export default class Scanner extends Component {
	constructor(props) {
		super(props);
		this.waitingForScan = false;
		this.lastScanTime = 0;
		this.scanCount = 0;
		this.lastScanCode = '';
		this.state = {
			type: 'drug', //drug or box
			mode: 'check', //check or scan
			box_id: '',
			drugs: ''
		};
	}

	setupWebScanner = () => {
		if (this.webScannerRunning) return;
		this.webScannerRunning = true;
		if (this.webScanner && this.webScanner.start) {
			return this.webScanner.start();
		}

		let options = { video: document.getElementById('ScannerVideo'), scanPeriod: 4 };
		let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if (iOS) {
			options.mirror = false;
		}
		this.webScanner = new Instascan.Scanner(options);
		this.webScanner.addListener('scan', (content) => {
			this.scanSuccess(null, content);
		});
		Instascan.Camera.getCameras()
			.then((cameras) => {
				console.log(cameras);
				console.log(cameras[1]);
				if (cameras.length > 0) {
					this.webScanner.start(cameras[cameras.length - 1]);
				} else {
					console.error('No cameras found.');
				}
			})
			.catch(function (e) {
				console.error(e);
			});
	};

	getEmptyState = () => {
		return {
			action: null,
			type: 'drug',
			mode: 'check',
			box_id: '',
			drugs: '',
			title: '',
			subTitle: '',
			onSuccess: null,
			onFinish: null
		};
	};

	listenForHotKeys = () => {
		window.onkeydown = (e) => {
			// console.log('handleKeyPress', e.keyCode);
			const K = 75;
			if (e.metaKey && e.keyCode == K) {
				this.manualPrompt();
			}
			// console.log(e.keyCode);
		};
	};
	removeHotKeyListener = () => {
		window.onkeydown = (e) => {};
	};

	showScanner = (title, subTitle, onSuccess, onFinish, clearState = false) => {
		console.log('showScanner function');
		this.listenForHotKeys();
		let state = {};
		if (clearState) {
			state = this.getEmptyState();
		}

		if (title && subTitle) {
			state.title = title;
			state.subTitle = subTitle;
			state.onSuccess = onSuccess;
			state.onFinish = onFinish;
			this.setState(state);
		}

		this.hideRoot();
		if (!window.QRScanner) {
			this.setupWebScanner();
			return console.log('QRScanner not defined');
		}
		if (this.waitingForScan) {
			window.QRScanner.scan(this.scanSuccess);
			return;
		}
		this.waitingForScan = true;
		window.QRScanner.prepare(this.scanReady); // show the prompt
	};

	scanReady = (err, status) => {
		console.log('Scan prepare callback');
		this.hideRoot();
		if (err) {
			// here we can handle errors and clean up any loose ends.
			console.error(err);
			window.QRScanner.prepare(this.scanReady); // show the prompt
		}
		if (status.authorized) {
			// W00t, you have camera access and the scanner is initialized.
			// setTimeout(() => {
			this.hideRoot();
			// }, 500)
			window.QRScanner.scan(this.scanSuccess);
			setTimeout(window.QRScanner.show, 300);
			setTimeout(window.QRScanner.show, 800);
			setTimeout(() => {
				window.QRScanner.show(function (status) {
					console.log('Scanner show success', status);
				}, 3000);
			});
		} else if (status.denied) {
			console.error('denied');
			toasts.show('Enable camera use in settings');
			setTimeout(() => {
				window.QRScanner.openSettings();
			}, 1000);

			// window.QRScanner.prepare(this.scanReady);
			// The video preview will remain black, and scanning is disabled. We can
			// try to ask the user to change their mind, but we'll have to send them
			// to their device settings with `QRScanner.openSettings()`.
		} else {
			console.error('did not get permission');
			toasts.show('Enable camera use in settings');
			setTimeout(() => {
				window.QRScanner.openSettings();
			}, 1000);

			// window.QRScanner.prepare(this.scanReady);
			// we didn't get permission, but we didn't get permanently denied. (On
			// Android, a denial isn't permanent unless the user checks the "Don't
			// ask again" box.) We can ask again at the next relevant opportunity.
		}
	};

	scanSuccess = async (err, text, inputManually = false) => {
		this.scanCount++;
		console.log('scan success function');
		// if (tryAgain) setTimeout(() => { // uncomment to simulate double scan.
		//   this.scanSuccess(err, text, false);
		// }, 1);
		if (this.lastScanCode == text) {
			if (Date.now() - 2000 < this.lastScanTime) {
				this.lastScanCode = text;
				this.lastScanTime = Date.now();
				console.log('caught duplicate scan ' + this.scanCount + ' ' + text);
				return this.showScanner();
			}
		}
		this.lastScanTime = Date.now();
		this.lastScanCode = text;
		if (err) {
			// an error occurred, or the scan was canceled (error code `6`)
			window.f7.dialog.alert(err, 'Scan Error');
		} else {
			let valid = scannerUtils.validScan(text);
			if (!valid) return this.showScanner();
			this.waitingForScan = false;
			this.state.onSuccess(text, inputManually);
		}
	};

	closeModalsAndHideScanner = () => {
		window.popupController.hide(BoxActionsPopup);
		this.hideScanner();
		window.f7.dialog.close();
	};

	destroyWebScanner = () => {
		this.webScannerRunning = false;
		if (this.webScanner) this.webScanner.stop();
	};

	hideScanner = () => {
		this.removeHotKeyListener();
		this.showRoot();
		this.setState({ drugs: null });
		if (!window.QRScanner) return this.destroyWebScanner();
		this.waitingForScan = false;
		window.QRScanner.hide((status) => {
			console.log('Scanner Hide', status);
			window.QRScanner.destroy((status) => {
				console.log('Scanner destroy', status);
				this.showRoot();
			});
		});
	};

	hideRoot = () => {
		$('#root').addClass('transparent');
		$('body').addClass('transparent');
		$('html').addClass('transparent');
	};

	showRoot = () => {
		$('#root').removeClass('transparent');
		$('body').removeClass('transparent');
		$('html').removeClass('transparent');
	};

	manualPrompt = () => {
		window.f7.dialog.close();
		let user_group = models.user_group.obj();
		if (user_group?.allow_manual_scan_entry || !user_group) {
			window.popupController.hide(BoxActionsPopup);
			window.f7.dialog.prompt('Enter the code', 'Manual Entry', this.manualBarcode);
			setTimeout(() => {
				document.querySelectorAll('.item-input-wrap > .dialog-input')[0].focus();
			}, 500);
		} else {
			toasts.show('Manual Entry is not enabled for your organization');
		}
	};

	manualBarcode = (value) => {
		// if(value.length < 1){
		//   window.f7.prompt('Enter the barcode (at least 1 character)', 'Barcode', this.manualBarcode);
		//   return;
		// }
		this.scanSuccess(null, value, true);
	};

	_getHelpText = () => {
		return <p>{this.state.subTitle}</p>;
	};

	_getTitle = () => {
		return this.state.title;
	};

	setDrugs = (drugs, action) => {
		this.setState({ drugs: drugs, action });
	};

	_displayScannedDrugs = () => {
		let drugs = this.state.drugs;
		if (!drugs) {
			return null;
		}
		let drugsBarcodeId = [];
		let counter = 1;
		for (let i in drugs) {
			let drug = drugs[i];

			let id = <li>{`${counter}. ${utils.upperCase(drug.barcode_id)}`}</li>;
			drugsBarcodeId.push(id);
			counter++;
		}
		return drugsBarcodeId;
	};

	_displayCreateButton = () => {
		let drugs = this.state.drugs;
		if (!drugs) {
			return null;
		} else if (drugs.length === 1) {
			return (
				<a className="button create center g-button g-blue bg-white" href="/#" onClick={this._createDrugs}>
					{this.state.action} Drug
				</a>
			);
		} else {
			return (
				<a className="button create center g-button g-blue bg-white" href="/#" onClick={this._createDrugs}>
					{this.state.action} Drugs
				</a>
			);
		}
	};

	_createDrugs = () => {
		this.state.onFinish({}, this.state.drugs);
	};

	renderWebScannerVideo = () => {
		if (window.QRScanner) return null;
		return <video playsInline={true} id="ScannerVideo"></video>;
	};

	render() {
		return (
			<div className="view view-main navbar-through g-scanner" data-page="null">
				<div className="navbar">
					<div className="navbar-inner navbar-on-center">
						<div className="sliding left">
							<button className="button button-link" onClick={this.closeModalsAndHideScanner}>
								<i className="icon-back icon"></i>
								Back
							</button>
						</div>
						<div className="sliding center">{this._getTitle()}</div>
						<div></div>
					</div>
				</div>
				<div className="pages">
					<div className="page page-on-center">
						{this.renderWebScannerVideo()}
						<div className="gray-box top">
							<div className="page-content">{this._getHelpText()}</div>
							<div className="display-scanned-drugs">
								<ul>{this._displayScannedDrugs()}</ul>
							</div>
						</div>
						<div className="side-row">
							<div className="gray-box side left">
								<FontIcon size="xl" color="blue" className="top-left" name="icon-keyboard-arrow-down"></FontIcon>
								<FontIcon size="xl" color="blue" className="bottom-left" name="icon-keyboard-arrow-down"></FontIcon>
							</div>
							<div className="scan-bar" />
							<div className="gray-box side right">
								<FontIcon size="xl" color="blue" className="top-right" name="icon-keyboard-arrow-down"></FontIcon>
								<FontIcon size="xl" color="blue" className="bottom-right" name="icon-keyboard-arrow-down"></FontIcon>
							</div>
						</div>
						<div className="gray-box bottom">
							{this._displayCreateButton()}
							<p className="manual-text">Can't scan the code?</p>
							<Button bg="white" center className="enter-manually" onClick={this.manualPrompt}>
								Enter Manually
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
