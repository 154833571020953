import React from 'react';
import './Signup.css';
import { Link, Navbar, Page } from 'framework7-react';
import Input from '../../input/Input';
import Button from '../../button/Button';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import mainAPI from 'api/MainAPI.js';
import toasts from '../../../utils/toasts';

export default class Signup extends FormTemplate {
	allFieldsValid = () => {
		// let value = this.getValue();
		// if(value.password.length < 8){
		//   toasts.show('Passwords must be 8 characters or longer');
		//   return false;
		// }
		return true;
	};

	continue = async () => {
		if (!this.allFieldsValid()) return;
		if (!this.isValid()) return;
		let user = this.getValue();
		user.question_or_comment = 'Filled out the contact us form in the app';
		toasts.show('Thank you for contacting us. A member of our team will contact you shortly.');
		await mainAPI.contact_us.create(user);
		window.f7.views.login.router.back();
	};

	render() {
		return (
			<Page className="g-signup">
				<Navbar title=" " backLink="Back"></Navbar>
				<Input ref={(ref) => (this.myRefs.first_name = ref)} required iconName="icon-person" placeholder="First Name" />
				<Input ref={(ref) => (this.myRefs.last_name = ref)} required iconName="icon-person" placeholder="Last Name" />
				<Input
					ref={(ref) => (this.myRefs.agency_name = ref)}
					required
					iconName="icon-flag"
					placeholder="Organization Name"
				/>
				<Input ref={(ref) => (this.myRefs.email = ref)} required iconName="icon-email" placeholder="Email Address" />
				<Input ref={(ref) => (this.myRefs.phone = ref)} iconName="icon-phone" placeholder="Phone Number" />
				<Button color="white" bg="blue" onClick={this.continue}>
					Continue
				</Button>
				<p>
					Already have an account? <Link href="/login/">Log-in</Link>
				</p>
				<div>
					<Button color="white" bg="blue" href="/forgot/password/">
						Forgot Password
					</Button>
				</div>
			</Page>
		);
	}
}
