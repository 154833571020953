let lastLocation = {};
let getLocation = () => {
	return new Promise((resolve) => {
		if (!navigator.geolocation) return resolve();
		let timeOutLimit = 1000 * 30;
		let maximumAgeLimit = 1000 * 60 * 60;
		let options = {
			enableHighAccuracy: true,
			timeOut: timeOutLimit,
			maximumAge: maximumAgeLimit
		};

		let onPositionReceived = (position) => {
			let lat = position.coords.latitude;
			let lon = position.coords.longitude;
			let locationObject = { lat, lon };
			lastLocation = locationObject;
			resolve(locationObject);
		};

		let onFailedPositionReceived = (err) => {
			console.error('could not get location');
			resolve();
		};
		navigator.geolocation.getCurrentPosition(onPositionReceived, onFailedPositionReceived, options);
	});
};

export function getLastLocation() {
	return lastLocation;
}

export default getLocation;
