class general_endpoint {
	constructor(table_name, requests) {
		this.table_name = table_name;
		this.requests = requests;
	}

	get = (id) => {
		return this.requests.get(this.table_name, { id: id });
	};
	update = (obj) => {
		return this.requests.post(this.table_name + '/update', obj);
	};
	delete = (obj) => {
		return this.requests.post(this.table_name + '/delete', obj);
	};
	create = (obj, errorHandler) => {
		return this.requests.post(this.table_name + '/create', obj, null, errorHandler);
	};
	all = (obj) => {
		return this.requests.get(this.table_name + '/all', obj);
	};
	for_user = (obj) => {
		return this.requests.get(this.table_name + '/for_user', obj);
	};
	metadata = (obj) => {
		return this.requests.get(this.table_name + '/metadata', obj);
	};
	update_many = (obj) => {
		return this.requests.postReturnError(this.table_name + '/update/many', { data: obj });
	};
}

export default general_endpoint;
