import objUtils from '../utils/objUtils';
import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';
import DrugTransferPopup from '../components/popups/drugTransferPopup/DrugTransferPopup';
import scanViewController from './scanViewController';

let TransferDrugController = function () {
	this.drugs = [];
	this.transferAgency = false;
	this.start = (drugs, transferAgency) => {
		this.transferAgency = transferAgency;
		this.drugs = drugs || [];
		scanViewController.setDrugs(drugs, 'Transfer');
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner(
			'Scan a Drug',
			'Place the scan area on the code',
			this._handleScan,
			this._transferDrugs
		);
	};
	this._handleScan = async (text) => {
		let drug = await mainAPI.drug.find_by_barcode({ barcode_id: text });
		if (!drug) {
			setTimeout(this.showScanner, 1000);
			return toasts.show('No drug found with that code');
		}
		if (this.transferAgency) {
			const info = await mainAPI.stock_move_record.userGroupTransferInfo(drug.id);
			if (info.drug_in_other_agency) {
				setTimeout(this.showScanner, 1000);
				return toasts.show('Drug is in another agency. Someone from that agency can transfer it back.');
			}
		}
		this._onAddAnother(drug);
	};
	this._onAddAnother = (drug) => {
		this.drugs.push(drug);
		let drugs = objUtils.to_obj(this.drugs, 'barcode_id');
		this.drugs = objUtils.to_array(drugs);
		scanViewController.setDrugs(this.drugs, 'Transfer');
		this.showScanner();
	};

	this._transferDrugs = () => {
		let drugIds = [];
		for (let i in this.drugs) {
			drugIds.push(this.drugs[i].id);
		}
		window.popupController.hideAll();
		window.popupController.show(DrugTransferPopup, {
			transferAgency: this.transferAgency,
			drugIds: JSON.stringify(drugIds)
		});
		scanViewController.hideScanner();
	};
};

let transferDrugController = new TransferDrugController();

export default transferDrugController;
