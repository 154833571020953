import React, {Component} from 'react';
import './ImageInput.css';
import axios from "axios";
import mainAPI from "../../api/MainAPI";

export default class ImageInput extends Component {
  constructor(props){
    super(props)
    this.state = {
      id: Date.now() // needs to be unique.
    }
  }

  getValue = () => {
     return this.state.imageUrl
  }

  uploadImage = async (file) => {
    const url =  mainAPI.rootURL() + '/api/v1/image/upload?token=' + localStorage.token;
    const formData = new FormData();
    formData.append('file', file, file.name);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        token: localStorage.token,
        last_log_in: Date.now(),
      }
    };
    return await axios.post(url, formData, config);
  }
  getImage = async (event) => {
    this.setState({uploading:true})
    const file = event.target.files[0];
    const response = await this.uploadImage(file).catch(() => {
       this.setState({uploading:false})
    });
    const imageUrl = response?.data?.data?.url;
    const fullUrl =  mainAPI.rootURL() + imageUrl + "&token=" + localStorage.token + '&last_log_in=' + Date.now();
    this.setState({imageUrl, fullUrl, uploading:false})
  }
  render(){
    let text = 'add image';
    if(this.state.imageUrl) text = 'change image';
    if(this.state.uploading) text = 'uploading';
    return(
    <div className="g-image-input">
      {this.state.imageUrl && <img src={this.state.fullUrl} alt={'inventory-check'} />}
      <label htmlFor={'g-image-input-' + this.state.id} onChange={this.getImage}>{text}</label>
      <input id={'g-image-input-' + this.state.id} onChange={this.getImage} type='file' accept="image/png, image/jpeg"
             capture="environment"/>
    </div>
    )
  }
}