import React, { Component } from 'react';
import './Signature.css';
import SignaturePad from 'signature_pad';
import utils from '../../utils/utils';

export default class Signature extends Component {
	componentDidMount = () => {
		this.signaturePad = new SignaturePad(this.canvas);
		setTimeout(this.resizeCanvas, 500);
	};

	resizeCanvas = () => {
		let ratio = Math.max(window.devicePixelRatio || 1, 1);
		this.canvas.width = this.canvas.offsetWidth * ratio;
		this.canvas.height = this.canvas.offsetHeight * ratio;
		this.canvas.getContext('2d').scale(ratio, ratio);
		this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
	};

	getValue = () => {
		// Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
		//     signaturePad.toDataURL(); // save image as PNG
		//     signaturePad.toDataURL("image/jpeg"); // save image as JPEG
		let svg = this.signaturePad.toDataURL(); // save image as SVG
		// let svg = this.signaturePad.toDataURL("image/svg+xml"); // save image as SVG
		return svg;
	};

	isValid = () => {
		if (!this.props.required) return true;
		if (this.signaturePad.isEmpty()) return false;
		return true;
	};

	render() {
		return (
			<div className="g-signature">
				<canvas onTouchStart={utils.hideKeyboard} onMouseDown={utils.hideKeyboard} ref={(ref) => (this.canvas = ref)} />
				<div className={'x'}>X</div>
				<div className={'draw-here'}>Draw Here</div>
			</div>
		);
	}
}
