import models from '../model/models';

let GramUtils = function () {
	this.toMicrograms = (quantity, drugType) => {
		let types = models.drug_type.objs();
		if (types[drugType].unit_of_measurement == 'mg') {
			return quantity * 1000;
		} else if (types[drugType].unit_of_measurement == 'g') {
			return quantity * 1000 * 1000;
		}
		return quantity;
	};
};

export const getUnit = (type) => {
	let typeData = models.drug_type.obj(type);
	if (!typeData) {
		if (type) console.log(type);
		return '';
	}
	return typeData.unit_of_measurement || 'mg';
};
export const getDisplayUnit = (type) => {
	let typeData = models.drug_type.obj(type);
	if (!typeData) {
		if (type) console.log(type);
		return '';
	}
	return typeData.display_unit_of_measurement || typeData.unit_of_measurement || 'mg';
};

let gramUtils = new GramUtils();

export default gramUtils;
