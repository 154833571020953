import React, { Component } from 'react';
import Button from '../button/Button';
export default class SecondaryButton extends Component {
	render() {
		return (
			<Button bg="blue" color="white" {...this.props}>
				{this.props.children}
			</Button>
		);
	}
}
