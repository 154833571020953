import React from 'react';
import './HowToScan.css';
import HowToView from '../howToView/HowToView';
import iPhone from 'assets/iphone.png';
import FontIcon from '../../../fontIcon/FontIcon';

export default class HowToScan extends HowToView {
	constructor(props) {
		super(props);
		this.state = {
			message: 'Turn your mobile device into a scanner',
			nextPage: 'how-to-daily-checks',
			activeIndex: 1
		};
	}

	classes = () => {
		return 'g-how-to-scan';
	};
	content = () => {
		return [<img key={1} className="iphone" src={iPhone} alt={'iphone'} />, <FontIcon key={2} name="icon-crop-free" />];
	};
}
