import GeneralModel from './GeneralModel';

export default class UserGroupModel extends GeneralModel {
	obj = () => {
		for (let i in this.Objects) {
			return this.Objects[i];
		}
		return null;
	};
}
