import { About } from './components/pages/About';
import { Form } from './components/pages/Form';
import Scanner from './components/pages/scanner/Scanner';
import Login from './components/pages/login/Login';
import Signup from './components/pages/signup/Signup';
import HowToDailyChecks from './components/pages/tutorial/howToDailyChecks/HowToDailyChecks';
import HowToStatus from './components/pages/tutorial/howToStatus/HowToStatus';
import HowToReports from './components/pages/tutorial/howToReports/HowToReports';
import HowToKeepTrack from './components/pages/tutorial/howToKeepTrack/HowToKeepTrack';
import BoxTransferPage from './components/pages/boxTransferPage/BoxTransferPage';
import ForgotPasswordPage from './components/pages/forgotPasswordPage/ForgotPasswordPage';
import BoxCheckConfirmPage from './components/pages/boxCheckConfirmPage/BoxCheckConfirmPage';
import AdministerDetailsPage, {
	administerDetailsPageRoute
} from './components/pages/administerDetailsPage/AdministerDetailsPage';
import VerifyPage, { verifyPageRoute } from './components/pages/verifyPage/VerifyPage';
import WasteDrugDetailsPage, {
	wasteDrugDetailsRoute
} from './components/pages/wasteDrugDetailsPage/WasteDrugDetailsPage';
import GuestVerifyPage, { guestVerifyPageRoute } from './components/pages/guestVerifyPage/GuestVerifyPage';
import ModifyIncidentPage from './components/pages/modifyIncidentPage/ModifyIncidentPage';
import DrugHistoryPage from './components/pages/drugHistoryPage/DrugHistoryPage';
import DashBoardPage, {DashBoardPageRoute} from "./components/pages/dashboardPage/DashBoardPage";
import UnAdministerMultiUsePage, {unAdministerMultiUsePageRoute} from "./components/pages/unAdministerMultiUsePage/UnAdministerMultiUsePage";

export const routes = [
	{
		...DashBoardPageRoute,
		component: DashBoardPage
	},
	{
		path: '/about/',
		component: About
	},
	{
		path: '/form/',
		component: Form
	},
	{
		path: '/login/',
		component: Login
	},
	{
		path: '/signup/',
		component: Signup
	},
	{
		path: '/scanner/',
		component: Scanner
	},
	{
		path: '/drug/transfer/:drugIds',
		component: BoxTransferPage
	},
	{
		path: '/box/check/confirm/:id',
		component: BoxCheckConfirmPage
	},
	{
		path: '/box/check/confirm/:id/:timeStamp',
		component: BoxCheckConfirmPage
	},
	{
		path: verifyPageRoute,
		component: VerifyPage
	},
	{
		path: '/modify/incident',
		component: ModifyIncidentPage
	},
	{
		path: '/drug/history',
		component: DrugHistoryPage
	},
	{
		path: administerDetailsPageRoute,
		component: AdministerDetailsPage
	},
	{
		...unAdministerMultiUsePageRoute,
		component: UnAdministerMultiUsePage
	},
	{
		path: '/administer/details/:value',
		component: AdministerDetailsPage
	},
	{
		path: wasteDrugDetailsRoute,
		component: WasteDrugDetailsPage
	},
	{
		path: '/forgot/password',
		component: ForgotPasswordPage
	},
	{
		path: '/how-to-daily-checks',
		component: HowToDailyChecks
	},
	{
		path: '/how-to-status',
		component: HowToStatus
	},
	{
		path: '/how-to-reports',
		component: HowToReports
	},
	{
		path: guestVerifyPageRoute,
		component: GuestVerifyPage
	},
	{
		path: '/how-to-keep-track',
		component: HowToKeepTrack
	}
];
