import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';
import scanViewController from './scanViewController';
import {
  administerDetailsPageRoute,
  administerOneDrug,
  checkIfResealRequired
} from '../components/pages/administerDetailsPage/AdministerDetailsPage';
import verifyController from './verifyController';
import models from '../model/models';
import DrugScanned from '../components/popups/drugScanned/DrugScanned';
import { getLastLocation } from '../utils/getLocation';

let BatchAdministerController = function () {
  const self = this;
  this.administrations = [];
  this.incident_number = '';
  this.sending = false;
  this.reset = () => {
    this.administrations = [];
    this.sending = false;
    this.incident_number = null;
  };

  this.showScanner = () => {
    scanViewController.showScanner('Scan a Drug', 'Place the scan area on the code', this.handleScan);
  };

  this.handleScan = async (text) => {
    let drug = await mainAPI.drug.find_by_barcode({ barcode_id: text });
    if (!drug) return toasts.show('No drug found with that code');
    this.showAdministerDetails(drug);
  };

  this.onAddAnother = (drugId, formValue, drugType, barcodeId, incident_number) => {
    this.incident_number = incident_number;
    upsert({ drugId, formValue, drugType, barcodeId });
    this.showScanner();
  };

  /**
   * Upsert a new administration into an array of administrations.
   *
   * @param {Object} administration - The administration object to upsert.
   * @param {string} administration.drugId - The unique identifier for the drug.
   * @returns {Object} An object indicating the result of the upsert operation.
   * @returns {boolean} result.updated - Indicates whether an existing administration was updated (true) or not (false).
   * @returns {boolean} result.inserted - Indicates whether a new administration was inserted (true) or not (false).
   */
  function upsert(administration) {
    const {drugId} = administration;
    let result = {updated: false, inserted: false}
    self.administrations = self.administrations.map((original) => {
      const {drugId: targetId} = original;
      if (targetId === drugId) {
        result = {...result, updated: true};
        return administration;
      }
      return original;
    });
    const {updated} = result;
    if (!updated) {
      result = {...result, inserted: true};
      self.administrations.push(administration);
    }
    return result;
  }

  this.showAdministerDetails = (drug) => {
    scanViewController.hideScanner();

    window.f7.views.current.router.navigate(`${administerDetailsPageRoute}?force-navigate=${drug.id}`, {
      props: {
        incident_number: this.incident_number,
        drugBarcodes: barcodes({barcodeId: drug.barcode_id}),
        value: {
          id: drug.id,
          barcodeId: drug.barcode_id
        },
        onBackPress: () => {
          this.removeOne(drug.id);
        }
      }
    });
  };

  this.removeOne = (drugId) => {
    this.administrations = this.administrations.filter((admin) => {
      if (admin.drugId == drugId) {
        return false;
      }
      return true;
    });
  };

  this.getNumberOfAdministrations = () => {
    return this.administrations.length;
  };

  this.getAllDrugIds = (administrations) => {
    let allDrugIds = [];
    for (let i in this.administrations) {
      allDrugIds.push(this.administrations[i].drugId);
    }
    return allDrugIds;
  };

  this.addLastAdministration = async (drugId, formValue, drugType, barcodeId, incident_number) => {
    this.incident_number = incident_number;
    upsert({drugId, formValue, drugType, barcodeId});
    const isControlledSubstance = this.administrations.find((administer) => {
      return models.drug_type.isVerifyRequired(administer.drugType);
    });
    let allDrugIds = this.getAllDrugIds(this.administrations);
    if (isControlledSubstance) {
      verifyController.start(async (verifier) => {
        await this.onVerify(verifier, allDrugIds);
      });
    } else {
      await this.onVerify({}, allDrugIds);
    }
  };

  this.administerDrugs = async (verifier) => {
    while (true) {
      const details = this.administrations.pop();
      if (!details) break;
      await this.administerOne(verifier, details);
    }
  };

  this.onVerify = async (verifier, allDrugIds) => {
    await this.administerDrugs(verifier);
    window.popupController.hide(DrugScanned);
    models.drug.refreshData();
    checkIfResealRequired(allDrugIds, verifier);
    this.reset();
  };

  this.administerOne = async (verifier, administerDetails) => {
    const location = getLastLocation();
    await administerOneDrug(verifier, administerDetails.formValue, administerDetails.drugId, location);
  };

  /**
   * Retrieves an array of barcode IDs in reverse order, optionally including a provided barcode ID at the front.
   *
   * @param {Object} options - An object containing options for retrieving barcode IDs.
   * @param {string} options.barcodeId - An optional barcode ID to include in the result.
   * @returns {string[]} - An array of barcode IDs, possibly including the provided barcode ID.
   */
  function barcodes({barcodeId} = {}){
    if (self.administrations.length === 0) {
        const result = (barcodeId) ? [barcodeId]: [];
        return result;
    }
    const result = self.administrations.map(({barcodeId}) => barcodeId);
    result.reverse();
    if(barcodeId && !result.includes(barcodeId)) result.unshift(barcodeId);
    return result;
  }
};

let batchAdministerController = new BatchAdministerController();

export default batchAdministerController;
