import React from 'react';
import { Popup } from 'framework7-react';
import './SignaturePopup.css';
import Signature from '../../signature/Signature';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import PrimaryButton from '../../primaryButton/PrimaryButton';

export default class SignaturePopup extends FormTemplate {
	constructor(props) {
		super(props);
		this.myRefs = {};
	}

	close = () => {
		window.popupController.hide(SignaturePopup);
	};

	finish = () => {
		const img = this.myRefs.guest_verify__signature_img.getValue();
		this.props.onFinish(img);
	};

	render() {
		return (
			<Popup className={'g-signature-popup'} opened={this.props.opened} tabletFullscreen={true}>
				<Content>
					<Label dark large center>
						Signature
					</Label>
					<Signature required ref={(ref) => (this.myRefs.guest_verify__signature_img = ref)} />
					<PrimaryButton onClick={this.finish}>done</PrimaryButton>
				</Content>
			</Popup>
		);
	}
}
