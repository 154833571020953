import scanViewController from './scanViewController';
import getLocation, { getLastLocation } from '../utils/getLocation';
import { OfflineTypes } from '../model/offlineStorageModel';
import toasts from '../utils/toasts';
import offlineStorageModel from '../model/offlineStorageModel';
import { wasteDrugDetailsRoute } from '../components/pages/wasteDrugDetailsPage/WasteDrugDetailsPage';

const OfflineWasteController = function () {
	this.start = (text, inputManually = false) => {
		getLocation();
		if (text) {
			return this.onScanText(text, inputManually);
		}
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner('Offline Waste', 'Scan a drug code', this.onScanText, null, true);
	};

	this.onScanText = (text, inputManually) => {
		window.f7.views.current.router.navigate(wasteDrugDetailsRoute, {
			props: {
				offline: true,
				quantity: null,
				id: null,
				onComplete: (verifier, notes, quantityFromInput, reverseDistributor) => {
					return this.complete(
						{
							barcode_id: text,
							notes,
							reverse_distributor: reverseDistributor,
							quantityFromInput
						},
						inputManually,
						verifier
					);
				}
			}
		});
		scanViewController.hideScanner();
	};

	this.complete = (value, inputManually, verify) => {
		let location = getLastLocation();
		offlineStorageModel.append(OfflineTypes.Waste, {
			...value,
			...location,
			input_barcode_manually: inputManually ? 1 : 0,
			time: Date.now(),
			guest_verify__name: verify.guest_verify__name,
			guest_verify__signature_img: verify.guest_verify__signature_img,
			guest_verify__license_number: verify.guest_verify__license_number,
			guest_verify__title: verify.guest_verify__title
		});
		toasts.show('Waste will be recorded when you are online again');
		window.f7.views.current.router.back();
		setTimeout(() => {
			window.f7.views.current.router.back();
		}, 700);
		return true;
	};
};

const offlineWasteController = new OfflineWasteController();

export default offlineWasteController;
