import React, { Component } from 'react';
import './TutorialPopup.css';
import { Popup, View } from 'framework7-react';
import HowToScan from '../../pages/tutorial/howToScan/HowToScan';

export default class TutorialPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: this.props.opened
		};
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({ opened: nextProps.opened });
	};

	close = () => {
		this.setState({ opened: false });
		window.popupController.hide(TutorialPopup);
	};

	render() {
		return (
			<Popup tabletFullscreen opened={this.state.opened} className="g-tutorial-popup" id="TutorialPopup">
				<View stackPages>
					<HowToScan />
				</View>
			</Popup>
		);
	}
}
