import React from 'react';
import './HowToReports.css';
import HowToView from '../howToView/HowToView';
import Content from '../../../helpers/content/Content';
import Graph from 'assets/graph.png';
import FontIcon from '../../../fontIcon/FontIcon';
import TutorialPopup from '../../../popups/tutorialPopup/TutorialPopup';

export default class HowToReports extends HowToView {
	constructor(props) {
		super(props);
		this.state = {
			message: 'Secure reporting and analytics at your fingertips',
			nextPage: 'none',
			activeIndex: 5
		};
	}

	continueClick = () => {
		window.popupController.hide(TutorialPopup);
	};

	classes = () => {
		return 'g-how-to-reports';
	};
	content = () => {
		return (
			<Content>
				<img className="graph" src={Graph} alt="report-graph" />
				<FontIcon name="icon-lock" />
			</Content>
		);
	};
}
