import React from 'react';
import { Navbar, Page } from 'framework7-react';
import Button from '../button/Button';

export const About = () => {
	return (
		<Page>
			<Navbar title="Scan a Drug" backLink="Back" sliding></Navbar>
			<p>Place the scan area below on the barcode</p>
			<Button className="enter-manually" center color="blue" bg="white">
				Enter Manually
			</Button>
		</Page>
	);
};
