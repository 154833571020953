import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';
import { wasteDrugDetailsRoute } from '../components/pages/wasteDrugDetailsPage/WasteDrugDetailsPage';
import BatchWasteOptionPopup from '../components/popups/batchWasteOptionPopup/BatchWasteOptionPopup';
import batchWasteController from './batchWasteController';
import gramUtils from '../utils/gramUtils';
import getLocation, { getLastLocation } from '../utils/getLocation';
import models from '../model/models';
import resealController from './resealController';

const WasteController = function () {
	this.sending = false;
	this.drug = null;
	this.start = (drug) => {
		this.reset();
		this.drug = drug;
		getLocation();
		window.popupController.show(BatchWasteOptionPopup, {
			onYesAddMore: this._startBatchWaste,
			onNoWasteOne: this._wasteDrugDetails
		});
	};

	this._startBatchWaste = async () => {
		batchWasteController.start(this.drug);
		this.reset();
	};

	this._wasteDrugDetails = async () => {
		const drugDetails = await mainAPI.drug.get(this.drug.id);
		window.f7.views.current.router.navigate(wasteDrugDetailsRoute, {
			props: {
				id: this.drug.id,
				quantity: drugDetails.stock_move_record__quantity,
				drugDetails,
				onComplete: this._wasteDrug
			}
		});
	};

	this.reset = () => {
		this.sending = false;
		this.drug = null;
	};

	this._wasteDrug = async (verifier, notes, quantityFromInput, reverseDistributor) => {
		const success = await wasteDrug(
			verifier,
			notes,
			quantityFromInput,
			this.drug.type,
			this.drug.id,
			reverseDistributor
		);
		if (!success) return false;
		toasts.show('Drug wasted');
		this._resealDrugBox(this.drug.id);
		this.reset();
		return success;
	};

	this._resealDrugBox = (drug_id) => {
		models.drug.refreshData();
		let user_group = models.user_group.obj();
		let drug = models.drug.obj(drug_id);
		let box = models.drug_box.obj(drug.drug_box_id);
		if (user_group.usage_requires_reseal) {
			resealController.foundBox(box, null);
		}
	};
};

export const wasteDrug = async (verifier, notes, quantityFromInput, drugType, drugId, reverseDistributor) => {
	let optionalProperties = {};
	if (quantityFromInput) {
		const quantity = -Math.abs(quantityFromInput); // force negative
		if (quantity) {
			optionalProperties.quantity = gramUtils.toMicrograms(quantity, drugType);
		} else {
			optionalProperties.waste_all = 1;
		}
	} else {
		optionalProperties.waste_all = 1;
	}

	let location = getLastLocation();
	let stockMoveRecord = {
		stock_move_type_id__name: 'waste',
		drug_id: drugId,
		...optionalProperties,
		witness_id: verifier.witness_id,
		witness_biometric_guid: verifier.guid,
		guest_verify__license_number: verifier.guest_verify__license_number,
		guest_verify__name: verifier.guest_verify__name,
		guest_verify__signature_img: verifier.guest_verify__signature_img,
		guest_verify__title: verifier.guest_verify__title,
		notes,
		lat: location.lat,
		lon: location.lon
	};
	if (reverseDistributor) {
		stockMoveRecord.reverse_distributor = reverseDistributor;
	}
	const success = await mainAPI.stock_move_record.create(stockMoveRecord);
	if (!success) {
		toasts.show('something went wrong');
		mainAPI.error_log.create({
			type: 'wasteController failed',
			message: 'failed to create' + JSON.stringify(stockMoveRecord)
		});
	}
	return success;
};
const wasteController = new WasteController();

export default wasteController;
