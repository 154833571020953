import React, { Component } from 'react';
import { Page, Navbar } from 'framework7-react';
import './DrugHistoryPage.css';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import mainAPI from '../../../api/MainAPI';
import Card from '../../card/Card';
import utils from '../../../utils/utils.js';
import Volume from '../../volume/Volume';

export default class DrugHistoryPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.adjustments = {};
	}

	componentDidMount = () => {
		this.getHistory();
	};

	getHistory = async () => {
		let history = await mainAPI.stock_move_record.forDrug(this.props.id);
		if (!history) history = [];

		this.setState({ history });
	};

	renderStockMovements = () => {
		if (!this.state.history) {
			return (
				<Label large dark center>
					Loading History
				</Label>
			);
		}
		if (this.state.history.length == 0) {
			return (
				<Label large dark center>
					No history
				</Label>
			);
		}
		let toRender = [];

		for (let i in this.state.history) {
			toRender.push(this.renderHistory(this.state.history[i]));
		}
		return toRender;
	};

	renderHistory = (history) => {
		let dt = utils.display_datetime(history.created_on);
		let type = history.stock_move_type__name
			.split('_')
			.map((val) => val[0].toUpperCase() + val.slice(1))
			.join(' ');
		return (
			<Card>
				<div className="i-row">
					<Label className="label">TYPE</Label>
					<Label dark bold>
						{type}
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">AMOUNT</Label>
					<Label dark bold>
						<Volume type={history.type} value={Math.abs(history.quantity)} />
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">USER</Label>
					<Label dark bold>
						{history.user_name}
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">WITNESS</Label>
					<Label dark bold>
						{history.guest_verify_name}
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">DATE</Label>
					<Label dark bold>
						{dt}
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">INCIDENT</Label>
					<Label dark bold>
						{history.incident_number}
					</Label>
				</div>
				<div className="i-row location">
					<Label dark bold>
						{history.location_name}
					</Label>
				</div>
			</Card>
		);
	};

	render() {
		return (
			<Page className="g-drug-history-page">
				<Navbar backLink="Back" title={this.props.drugCode} />
				<Content>{this.renderStockMovements()}</Content>
			</Page>
		);
	}
}
