import React, { Component } from 'react';
import { Popup } from 'framework7-react';
import './BatchTransferPopup.css';
import Card from '../../card/Card';
import Label from '../../label/Label';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import SecondaryButton from '../../secondaryButton/SecondaryButton';

export default class BatchTransferPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: true
		};
	}

	onYesAddMore = () => {
		this.close();
		this.props.onYesAddMore();
	};

	onNoBatchTransfer = () => {
		this.close();
		this.props.onNoBatchTransfer();
	};

	close = () => {
		window.popupController.hide(BatchTransferPopup);
	};

	render() {
		return (
			<Popup
				opened={this.props.opened}
				tabletFullscreen={true}
				className="g-batch-transfer-popup"
			>
				<div onClick={this.close} className="backdrop" />
				<Card>
					<Label dark large center>
						Batch Transfer
					</Label>
					<Label dark center>
						Would you like to add more?
					</Label>
					<PrimaryButton onClick={this.onYesAddMore}>Yes, add more</PrimaryButton>
					<SecondaryButton onClick={this.onNoBatchTransfer}>No, just this one</SecondaryButton>
				</Card>
			</Popup>
		);
	}
}
