import React from 'react';
import { Popup, View, Page } from 'framework7-react';
import './SelectUnitPopup.css';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import DropDown from '../../dropDown/DropDown';
import mainAPI from '../../../api/MainAPI';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import FontIcon from '../../fontIcon/FontIcon';
import toasts from '../../../utils/toasts';
import verifyController from '../../../controllers/verifyController';
import getLocation, { getLastLocation } from '../../../utils/getLocation';

export default class SelectUnitPopup extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = { value: {} };
		getLocation();
	}

	_verify = () => {
		if (!this.isValid()) return;
		verifyController.start(this._transfer);
	};

	_transfer = async (user) => {
		let value = this.getValue();
		let location = getLastLocation();
		value.transfer_lat = location.lat;
		value.transfer_lon = location.lon;
		value.witness_id = user.witness_id;
		value.witness_biometric_guid = user.guid;
		value.guest_verify__name = user.guest_verify__name;
		value.guest_verify__signature_img = user.guest_verify__signature_img;
		value.guest_verify__license_number = user.guest_verify__license_number;
		value.guest_verify__title = user.guest_verify__title;
		let success = await this.props.completeTransfer(value);
		if (!success) return toasts.show('Failed to transfer');
		this.close();
	};
	close = () => {
		window.popupController.hide(SelectUnitPopup);
	};

	render() {
		let value = this.state.value || {};
		return (
			<Popup opened={this.props.opened} tabletFullscreen={true} stackPages className="g-select-unit-popup">
				<View stackPages>
					<Page className="select-unit-popup-page">
						<FontIcon onClick={this.close} name="icon-close" color="black" />
						<Content>
							<Label dark center large>
								Select a Unit
							</Label>
							<DropDown
								required
								value={value.unit_id}
								ref={(ref) => (this.myRefs.unit_id = ref)}
								placeholder="Unit"
								endpoint={mainAPI.unit.all}
							/>
							<PrimaryButton onClick={this._verify}>Transfer</PrimaryButton>
						</Content>
					</Page>
				</View>
			</Popup>
		);
	}
}
