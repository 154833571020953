let ScannerUtils = function () {
	this.validScan = (value) => {
		let valid = true;
		if (value.length < 3) {
			valid = false;
			window.f7.dialog.alert('You need at least 3 characters, you have only entered ' + value.length, 'Scan Error');
		}
		if (value.indexOf(' ') > -1) {
			valid = false;
			window.f7.dialog.alert('You cannot have spaces in your code', 'Scan Error');
		}
		return valid;
	};
};

let scannerUtils = new ScannerUtils();

export default scannerUtils;
