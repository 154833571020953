import React from 'react';
import { Navbar, Page } from 'framework7-react';
import './AdministerDetailsPage.css';
import Content from '../../helpers/content/Content';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Label from '../../label/Label';
import mainAPI from '../../../api/MainAPI';
import models from '../../../model/models';
import verifyController from '../../../controllers/verifyController';
import DrugScanned from '../../popups/drugScanned/DrugScanned';
import getLocation, { getLastLocation } from '../../../utils/getLocation';
import toasts from '../../../utils/toasts';
import _ from 'lodash';
import Volume from '../../volume/Volume';
import gramUtils from '../../../utils/gramUtils';
import CheckBox from '../../checkBox/CheckBox';
import SecondaryButton from '../../secondaryButton/SecondaryButton';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import batchAdministerController from '../../../controllers/batchAdministerController';
import {resealNextBox} from "../boxTransferPage/BoxTransferPage";
import utils from "../../../utils/utils";
import offlineBatchAdministerController from "../../../controllers/offlineBatchAdministerController";
import {DashBoardPageRoute} from "../dashboardPage/DashBoardPage";

export const administerDetailsPageRoute = '/administer/details';
export default class AdministerDetailsPage extends FormTemplate {
	static defaultProps = {
		offline: false,
		drugBarcodes: [],
		incident_number: '',
		waste_remaining: false,
		quantity: '',
		quantity_wasted: 0,
		inputManually: false,
		value: {
			id: null,
			barcodeId: ''
		},
		onBackPress: () => {}
	};

	constructor(props) {
		super(props);

		const {incident_number, waste_remaining, quantity, quantity_wasted, inputManually, value} = this.props;

		this.state = {
			incident_number,
			waste_remaining,
			quantity,
			quantity_wasted,
			inputManually,
			value
		};
		this._onVerify = this._onVerify.bind(this);
		getLocation();
	}

	componentDidMount = () => {
		models.user_group.refreshData();
		this._getDrug(this.props);
	};

	componentWillReceiveProps = (nextProps) => {
		if (!nextProps || !nextProps.value) return;
		this._getDrug(nextProps);
	};

	_getDrug = async ({value, offline}) => {
		if (offline) return;
		value.quantity = this.state.quantity || value.quantity;
		value.quantity_wasted = this.state.quantity_wasted || value.quantity_wasted || 0;
		let drug = await mainAPI.drug.get(value.id);
		const quantity = Volume.inPreferredUnit(drug.stock_move_record__quantity, drug.type);
		const quantity_wasted = Volume.inPreferredUnit(value.quantity_wasted, drug.type);
		this.setState({
			quantity_wasted: this.state.quantity_wasted || quantity_wasted || 0,
			quantity: this.state.quantity || quantity,
			drug
		});
	};

	unUsedQuantityMcg = () => {
		const {drug: {stock_move_record__quantity} = {}} = this.state;
		return parseFloat(stock_move_record__quantity || 0);
	};

	_quantityChanged = () => {
		let value = this.getValue();
		const {offline} = this.props;
		if (offline) {
			this.setState({quantity: value.quantity});
			return;
		}
		let original = this.unUsedQuantityMcg();
		let types = models.drug_type.objs();
		const drugType = types[this.state.drug.type];
		const administered = gramUtils.toMicrograms(value.quantity, this.state.drug.type);
		if (this._moreThanTotalUsed(administered, original, this.state.drug.type)) return;
		if (this._negativeAdministered(administered)) return;
		value.quantity_wasted = Volume.inPreferredUnit(
			this._getWastedQuantity(drugType, original, administered),
			this.state.drug.type
		);
		this.setState({ ...value });
	};

	_getWastedQuantity(drugType, original, administered) {
		if (drugType.is_reusable) return 0;
		return original - administered;
	}

	_moreThanTotalUsed = (administered, original, type) => {
		if (administered > original) {
			toasts.show(
				'You cannot administer more than: ' + Volume.inPreferredUnit(original, type) + ' ' + Volume.displayUnit(type)
			);
			return true;
		}
		return false;
	};

	_negativeAdministered = (administered) => {
		if (administered < 0) {
			toasts.show('You cannot administer a negative amount');
			return true;
		}
		return false;
	};

	_zeroAdministered = (administered) => {
		if (administered == 0) {
			toasts.show('You cannot administer 0');
			return true;
		}
		return false;
	};

	validateAndReturnFormData = () => {
		if (!this.isValid()) return;
		let value = this.getValue();
		if (this._zeroAdministered(value.quantity)) return;
		if (this._negativeAdministered(value.quantity)) return;

		const {offline, value: {barcodeId, id: drug_id}} = this.props;

		const {drug: {type: drug_type} = {}} = this.state;

		if (!offline) {
			value.quantity = gramUtils.toMicrograms(value.quantity, drug_type);
			if (this._moreThanTotalUsed(value.quantity, this.unUsedQuantityMcg(), drug_type)) return;
			// When the app is online and the drug is reusable, then quantity_wasted will be undefined
			// and waste_remaining will be either true or false.
			//
			// When the app is online and the drug is not reusable, then quantity_wasted will be a number
			// and waste_remaining will default to false.
			if(value.quantity_wasted) {
				value.quantity_wasted = gramUtils.toMicrograms(value.quantity_wasted, drug_type);
				if (utils.isNumber(value.quantity_wasted)) {
					// When the app is online and the drug is not reusable, then waste_remaining checkbox is not
					// displayed. If the quantity_wasted is a number, then waste_remaining must be true.
					value.waste_remaining = true;
				}
			}
		}
		// Clone the state and update the values with the form values.
		// Conditional fields like waste_remaining and quantity_wasted will either ultimately
		// have the form value or the defined default value.
		let formValue = _.cloneDeep(this.state);
		for (let i in value) {
			formValue[i] = value[i];
		}
		const {quantity, incident_number, quantity_wasted, waste_remaining, inputManually} = formValue

		// When form data is validated and parsed
		// return a consistent object regardless of online and offline.
		// Values will be present for online and offline. This allows all the mapping
		// logic to be in one place. (INPUT FORM with all possible states -> values)
		const result = {
			quantity, 											// OFFLINE and ONLINE
			quantity_mcg: offline ? null : value.quantity, 		// ONLINE ONLY
			incident_number, 									// OFFLINE and ONLINE
			barcodeId, 											// OFFLINE and ONLINE
			waste_remaining, 									// OFFLINE and ONLINE
			quantity_wasted, 									// ONLINE calculated Value OFFLINE default 0
			drugId: drug_id, 									// ONLINE ONLY
			inputManually, 										// OFFLINE and ONLINE
			formValue, // TODO: Remove this and only return explicit values. (figure out exactly what values are needed for online)
			drugType: drug_type 								// ONLINE ONLY
		};
		console.log('result ', result);
		return result;
	};

	administer = async () => {
		const formData = this.validateAndReturnFormData();
		if (!formData) return;
		const {offline} = this.props
		if(offline) {
			const {barcodeId, incident_number, quantity, waste_remaining, inputManually} = formData
			const administration = {barcodeId, incident_number, quantity, waste_remaining, inputManually};
			offlineBatchAdministerController.addAdministration(administration, {showScanner: false});
			verifyController.startOnlyGuestVerify(async (verifier) => {
				offlineBatchAdministerController.enqueue({verifier})
				window.f7.views.current.router.navigate(DashBoardPageRoute, { reloadAll: true });
			});
		}
		else {
			const { formValue, drugId, drugType, barcodeId, incident_number } = formData;
			if (batchAdministerController.getNumberOfAdministrations() > 0) {
				await batchAdministerController.addLastAdministration(drugId, formValue, drugType, barcodeId, incident_number);
				return;
			}
			const isControlledSubstance = models.drug_type.isVerifyRequired(drugType);
			if (isControlledSubstance) {
				verifyController.start(async (verifier) => {
					await this._onVerify(verifier, formValue);
				});
			} else {
				await this._onVerify({}, formValue);
			}
		}
	};

	administerAnother = () => {
		const formData = this.validateAndReturnFormData();
		if (!formData) return;

		const {offline} = this.props

		if(offline) {
			const {barcodeId, incident_number, quantity, waste_remaining, inputManually} = formData
			const administration = {barcodeId, incident_number, quantity, waste_remaining, inputManually};
			offlineBatchAdministerController.addAdministration(administration);
		}
		else {
			const { formValue, drugId, drugType, barcodeId, incident_number } = formData;
			batchAdministerController.onAddAnother(drugId, formValue, drugType, barcodeId, incident_number);
			return;
		}
	};

	_onVerify = async (verifier, value) => {
		const {value: {id: drug_id} } = this.props;
		await administerOneDrug(verifier, value, drug_id, getLastLocation());
		window.popupController.hide(DrugScanned);
		this._resealDrugBox(drug_id, verifier);
	};

	_resealDrugBox = (drug_id,verifier) => {
		const {offline} = this.props
		if (offline) return;
		checkIfResealRequired([drug_id], verifier);
	};

	renderWasteRemaining() {
		return (
			<>
				<div className="check-row">
					<CheckBox
						ref={(ref) => (this.myRefs.waste_remaining = ref)}
						value={!!this.state.waste_remaining}
						onChange={(value) => {
							this.setState({ waste_remaining: value });
						}}
					/>
					<Label dark>Waste remaining?</Label>
				</div>
			</>
		);
	}
  
	renderWasteInput() {
		const {offline} = this.props
		if (offline) return this.renderWasteRemaining();

		const drugTypes = models.drug_type.objs();
		const drugType = this.state.drug ? drugTypes[this.state.drug.type] : null;

		if (drugType && drugType.is_reusable) return this.renderWasteRemaining();

		return (
			<>
				<Label dark>Amount wasted</Label>
				<Input
					disabled
					type="number"
					required
					ref={(ref) => (this.myRefs.quantity_wasted = ref)}
					value={this.state.quantity_wasted || 0}
					placeholder="Amount wasted"
				/>
			</>
		);
	}

	render() {
		const {value: {barcodeId}, drugBarcodes, onBackPress, offline} = this.props;
		const {incident_number, quantity, inputManually} = this.state;
		const {require_incident_number} = models.user_group.obj() || {}
		const title = `Drug ${barcodeId.toUpperCase()}`;
		const showAll = drugBarcodes && drugBarcodes.length > 1;
		const disableIncidentNumber = showAll;

		return (
			<Page className="g-administer-details-page">
				<Navbar backLink="Back" title={title} onBackClick={onBackPress} />
				<Content>
					<Label dark>Amount administered</Label>
					<Input
						onChange={this._quantityChanged}
						type="number"
						required
						ref={(ref) => (this.myRefs.quantity = ref)}
						value={quantity}
						placeholder="Amount"
					/>

					{this.renderWasteInput()}
					<Label dark>Incident number</Label>
					<Input
						disabled={disableIncidentNumber}
						type="text"
						required={offline || require_incident_number}
						ref={(ref) => (this.myRefs.incident_number = ref)}
						value={incident_number}
						onChange={(value) => {
							this.setState({ incident_number: value })
						}}
						placeholder={'Incident'}
						length="30"
					/>
					<Input type="hidden" wrapperClassName="hidden"
						   ref={(ref) => (this.myRefs.inputManually = ref)}
						   value={!!inputManually}
						   onChange={(value) => {
							   this.setState({ inputManually: value })
						   }}
					/>
					{showAll && (
						<div className="all-codes">
							<Label dark>All Codes</Label>
							<Label dark className={'codes'}>
								{drugBarcodes.join(', ').toUpperCase()}
							</Label>
						</div>
					)}
					<PrimaryButton onClick={this.administer} center>
						Administer
					</PrimaryButton>
					<SecondaryButton onClick={this.administerAnother} center>
						Add Another
					</SecondaryButton>
				</Content>
			</Page>
		);
	}
}

export const administerOneDrug = async (verifier, value, drug_id, location) => {
	if (value.waste_remaining) {
		const adminAndWaste = {
			drug_id,
			quantity: -Math.abs(value.quantity),
			witness_id: verifier.witness_id,
			witness_biometric_guid: verifier.guid,
			guest_verify__name: verifier.guest_verify__name,
			guest_verify__signature_img: verifier.guest_verify__signature_img,
			guest_verify__license_number: verifier.guest_verify__license_number,
			guest_verify__title: verifier.guest_verify__title,
			incident_number: value.incident_number,
			lat: location.lat,
			lon: location.lon
		};
		let success = await mainAPI.stock_move_record.administerAndWasteRemaining(adminAndWaste);
		if (!success) {
			mainAPI.error_log.create({
				type: 'AdministerDetailsPage.administerAndWasteRemaining Failed',
				message: 'failed to create' + JSON.stringify(adminAndWaste)
			});
			return toasts.show('Something went wrong');
		}
	} else {
		const administer = {
			stock_move_type_id__name: 'administer',
			drug_id,
			quantity: -Math.abs(value.quantity),
			witness_id: verifier.witness_id,
			witness_biometric_guid: verifier.guid,
			guest_verify__name: verifier.guest_verify__name,
			guest_verify__signature_img: verifier.guest_verify__signature_img,
			guest_verify__license_number: verifier.guest_verify__license_number,
			guest_verify__title: verifier.guest_verify__title,
			incident_number: value.incident_number,
			lat: location.lat,
			lon: location.lon
		};
		let success = await mainAPI.stock_move_record.create(administer);

		if (!success) {
			mainAPI.error_log.create({
				type: 'AdministerDetailsPage.create Failed',
				message: 'failed to create' + JSON.stringify(administer)
			});
			return toasts.show('Something went wrong');
		}
	}
};

export const checkIfResealRequired = (drugIdsArray, verifier, targetBoxIds = []) => {
	models.drug.refreshData();
	let user_group = models.user_group.obj();
  if (!user_group.usage_requires_reseal) return;
	let boxIdsArray = [];
	for (let i in drugIdsArray) {
		let drug = models.drug.obj(drugIdsArray[i]);
		boxIdsArray.push(drug.drug_box_id);
	}
	boxIdsArray = boxIdsArray.concat(targetBoxIds);
	let uniqueBoxIds = _.uniq(boxIdsArray);
  resealNextBox(uniqueBoxIds, verifier);
};
