import GeneralModel from './GeneralModel';

export default class DrugTypeModel extends GeneralModel {
	constructor(modelName, socket, allEndpoint) {
		super(modelName, socket, allEndpoint, 'drug_types');
	}

	isVerifyRequired = (drugTypeId) => {
		const isOptional =
			this.obj(drugTypeId)?.categories?.includes('Non-Controlled Substance') ||
			this.obj(drugTypeId)?.categories?.includes('Equipment');
		return !isOptional;
	};
	onlyOneQuantity = (drugTypeId) => {
		const onlyOneAllowed = this.obj(drugTypeId)?.categories?.includes('Equipment');
		return onlyOneAllowed;
	};

	allowed = () => {
		const isAllowed = {};
		for (let i in this.Objects) {
			if (this.Objects[i].allowed) {
				isAllowed[i] = this.Objects[i];
			}
		}
		return isAllowed;
	};
}
