import scanViewController from './scanViewController';
import OptionsPopup from '../components/popups/optionsPopup/OptionsPopup';
import offlineInventoryCheckController from './offlineInventoryCheckController';
import offlineWasteController from './offlineWasteController';
import offlineBatchAdministerController from "./offlineBatchAdministerController";

let OfflineScanController = function () {
	this.start = (text, inputManually) => {
		if (text) {
			this._handleScan(text, inputManually);
		}
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner('Scan a Drug', 'Offline mode', this._handleScan, null, true);
	};

	this._handleScan = async (text, inputManually) => {
		window.popupController.show(OptionsPopup, {
			handleSelect: (option) => {
				if (option === 'daily_check') {
					offlineInventoryCheckController.start(text, inputManually);
				} else if (option === 'administer') {
					offlineBatchAdministerController.start({text, inputManually});
				} else if (option === 'waste') {
					offlineWasteController.start(text, inputManually);
				}
			},
			option1: { label: 'Inventory Check', value: 'daily_check' },
			option2: { label: 'Administer', value: 'administer' },
			option3: { label: 'Waste', value: 'waste' }
		});
	};
};

let offlineScanController = new OfflineScanController();
export default offlineScanController;
