import _ from 'lodash';

let ListenForInteraction = function () {
	let lastId = Date.now();
	let callbacks = {};
	let isPaused = false;
	this.onInteraction = (callback) => {
		lastId++;
		callbacks[++lastId] = callback;
		return;
	};

	this.handleInteraction = _.debounce(() => {
		if (isPaused) return;
		for (let i in callbacks) {
			if (typeof callbacks[i] === 'function') {
				callbacks[i]();
			} else {
				delete callbacks[i];
			}
		}
	}, 1000);

	this.pause = () => {
		isPaused = true;
	};
	this.resume = () => {
		isPaused = false;
	};
	document.body.addEventListener('mousemove', this.handleInteraction);
	document.body.addEventListener('scroll', this.handleInteraction);
	document.body.addEventListener('keydown', this.handleInteraction);
	document.body.addEventListener('click', this.handleInteraction);
	document.body.addEventListener('touchstart', this.handleInteraction);
};

let listenForInteraction = new ListenForInteraction();
export default listenForInteraction;
