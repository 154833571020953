import mainAPI from '../api/MainAPI';
import offlineController from '../controllers/offlineController';

const barcodeLookup = async (barcode) => {
	const promises = [];
	promises.push(mainAPI.drug.find_by_barcode({ barcode_id: barcode }));
	promises.push(mainAPI.drug_box.find_by_barcode({ barcode_id: barcode }));
	const [drug, box] = await Promise.all(promises);
	return { drug, box };
};
export const barcodeAndOfflineLookup = async (barcode) => {
	const promises = [];
	promises.push(mainAPI.drug.find_by_barcode({ barcode_id: barcode }));
	promises.push(mainAPI.drug_box.find_by_barcode({ barcode_id: barcode }));
	promises.push(offlineController.isOnline());
	let [drug, box, online] = await Promise.all(promises);
	return { drug, box, online };
};

export default barcodeLookup;
