import scanViewController from './scanViewController';
import getLocation, { getLastLocation } from '../utils/getLocation';
import { OfflineTypes } from '../model/offlineStorageModel';
import toasts from '../utils/toasts';
import { guestVerifyPageRoute } from '../components/pages/guestVerifyPage/GuestVerifyPage';
import offlineStorageModel from '../model/offlineStorageModel';

const OfflineInventoryCheckController = function () {
	this.start = (text, inputManually = false) => {
		getLocation();
		if (text) {
			return this.onScanText(text, inputManually);
		}
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner('Offline Inventory Check', 'Scan a drug or box code', this.onScanText, null, true);
	};

	this.onScanText = (text, inputManually) => {
		window.f7.views.current.router.navigate(guestVerifyPageRoute, {
			props: {
				onSuccess: (verify) => {
					this.completeCheck(text, inputManually, verify);
				}
			}
		});
		scanViewController.hideScanner();
	};

	this.completeCheck = (text, inputManually, verify) => {
		let location = getLastLocation();
		offlineStorageModel.append(OfflineTypes.DailyCheck, {
			lat: location.lat,
			lon: location.lon,
			barcode_id: text,
			input_barcode_manually: inputManually ? 1 : 0,
			time: Date.now(),
			guest_verify__name: verify.guest_verify__name,
			guest_verify__signature_img: verify.guest_verify__signature_img,
			guest_verify__license_number: verify.guest_verify__license_number,
			guest_verify__title: verify.guest_verify__title
		});
		toasts.show('Inventory Check will be recorded when you are online again');
		window.f7.views.current.router.back();
	};
};

const offlineInventoryCheckController = new OfflineInventoryCheckController();

export default offlineInventoryCheckController;
