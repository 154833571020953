import { verifyPageRoute } from '../components/pages/verifyPage/VerifyPage';
import { guestVerifyPageRoute } from '../components/pages/guestVerifyPage/GuestVerifyPage';

let verifyController = new (function () {
	this.start = (onVerified, discrepancyMessage) => {
		window.f7.views.current.router.navigate(verifyPageRoute, {
			props: {
				onSuccess: onVerified,
        discrepancyMessage
			}
		});
	};
	this.startOnlyGuestVerify = (onVerified) => {
		window.f7.views.current.router.navigate(guestVerifyPageRoute, {
			props: {
				onSuccess: onVerified
			}
		});
	};
})();

export default verifyController;
