import io from 'socket.io-client';
import mainAPI from '../api/MainAPI';

let socket = io(mainAPI.rootURL());
let cbId = Date.now();

export default class SocketModel {
	constructor(model) {
		this.model = model;
		this.onCallbacks = {};
	}

	on = (userGroupId, callback) => {
		cbId++;
		this.onCallbacks[userGroupId] = this.onCallbacks[userGroupId] || {};
		this.onCallbacks[userGroupId][cbId] = callback;
		socket.on(this.model + '__' + userGroupId, (msg) => {
			let callbacks = this.onCallbacks[userGroupId];
			for (let i in callbacks) {
				callbacks[i](msg);
			}
		});
		return parseInt(cbId, 10);
	};

	off = (cbId) => {
		let groups = this.onCallbacks;
		for (let i in groups) {
			if (groups[i][cbId]) {
				delete groups[i][cbId];
			}
		}
	};

	emit = (socketGroupId, message) => {
		message.token = localStorage.token;
		message.socket_group_id = socketGroupId;
		socket.emit(this.model, message);
	};
}
