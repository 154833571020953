import React, { Component } from 'react';
import './HowToView.css';
import { Page } from 'framework7-react';
import Button from '../../../button/Button';
import Logo from 'assets/logolargewhite.png';

export default class HowToView extends Component {
	getClasses = () => {
		return 'g-how-to-view ' + this.classes();
	};

	classes = () => {
		return '';
	};

	content = () => {
		return null;
	};

	continueClick = () => {};

	render() {
		return (
			<Page className={this.getClasses()}>
				<div className="container">
					<img className="white-logo" src={Logo} alt="view-logo" />
					{dots(this.state.activeIndex)}
					<div className="content">{this.content()}</div>
					<h2>{this.state.message}</h2>
					<Button onClick={this.continueClick} href={'/' + this.state.nextPage} color="white" bg="blue">
						{' '}
						Continue
					</Button>
				</div>
			</Page>
		);
	}
}

let OpenDot = (props) => {
	return <div className="open-dot"></div>;
};
let ClosedDot = (props) => {
	return <div className="closed-dot"></div>;
};

let dots = (activeIndex) => {
	let dotArr = [];
	for (let i = 1; i <= 5; i++) {
		if (i === activeIndex) {
			dotArr.push(<ClosedDot />);
		} else {
			dotArr.push(<OpenDot />);
		}
	}
	return <div className="dots">{dotArr}</div>;
};
