import React, { Component } from 'react';
import { Popup, View } from 'framework7-react';
import BoxTransferPage from '../../pages/boxTransferPage/BoxTransferPage';
import FontIcon from '../../fontIcon/FontIcon';
import './DrugTransferPopup.css';

export default class DrugTransferPopup extends Component {
	close = () => {
		window.popupController.hide(DrugTransferPopup);
	};
	render() {
		return (
			<Popup
				onPopupBeforeDestroy={this.close}
				onPopupClose={this.close}
				tabletFullscreen
				opened={this.props.opened}
				className="g-drug-transfer-popup"
			>
				<FontIcon onClick={this.close} name="icon-close" color="white" />
				<View stackPages>
					<BoxTransferPage {...this.props} />
				</View>
			</Popup>
		);
	}
}
