import React, { Component } from 'react';
import './InventoryCard.css';
import Icon from '../../../icon/Icon';

export default class InventoryCard extends Component {
	render() {
		let vialsText = 'vials';
		if (this.props.count == 1) {
			vialsText = 'vial';
		}
		if (this.props.count <= 0) {
			return null;
		}

		return (
			<div className="g-inventory-card">
				<div className="top-row">
					<div>{this.props.drug}</div>
					{/*<FontIcon className="next" name="icon-chevron-right" color="blue"/>*/}
				</div>
				<Vials count={this.props.count}></Vials>
				<div className="bottom-row">
					<strong>{this.props.count}</strong>
					<div>{vialsText}</div>
					<strong>{this.props.total}</strong>
					<div>{this.props.displayUnit}</div>
				</div>
			</div>
		);
	}
}

class Vials extends Component {
	getVials = () => {
		let vials = [];
		for (let i = 1; i <= this.props.count; i++) {
			if (i > 10) break;
			vials.push(<Icon key={i} size="medium" name="vial-fill" />);
		}
		for (let i = this.props.count + 1; i <= 10; i++) {
			vials.push(<Icon key={i} size="medium" name="vial-unfill" />);
		}
		if (this.props.count > 10) {
			vials.push(<Icon key={11} size="medium" name="vial-plus" />);
		}
		return vials;
	};

	render() {
		return <div className="vials">{this.getVials()}</div>;
	}
}
