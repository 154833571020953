import React from 'react';
import './HowToKeepTrack.css';
import HowToView from '../howToView/HowToView';
import Content from '../../../helpers/content/Content';
import Icon from '../../../icon/Icon';

export default class HowToKeepTrack extends HowToView {
	constructor(props) {
		super(props);
		this.state = {
			message: 'Keep track of every vial and every milligram',
			nextPage: 'how-to-status',
			activeIndex: 3
		};
	}

	classes = () => {
		return 'g-how-to-keep-track';
	};
	content = () => {
		return (
			<Content>
				<Icon name="keep_track"></Icon>
			</Content>
		);
	};
}
