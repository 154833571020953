import mainAPI from '../api/MainAPI';

const OfflineController = function () {
	this.isOnline = () => {
		return new Promise(async (resolve) => {
			const timeoutId = setTimeout(() => {
				resolve(false);
			}, 4000);
			const health = await mainAPI.health.get();
			clearTimeout(timeoutId);
			if (!health?.online) {
				return resolve(false);
			}
			return resolve(true);
		});
	};
};

const offlineController = new OfflineController();
export default offlineController;
