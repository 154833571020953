import mainAPI from "../api/MainAPI";

const queue = [];
let toasts = new (function () {
	this.show = (text, time, report=true) => {
		queue.push({ text, time, report });
		if (queue.length === 1) this.handleQueue();
	};
	this.handleQueue = () => {
		const next = queue[0];
		if (!next) return;
		window.f7.toast.show({ text: next.text, position: 'top' });
    mainAPI.toasts.create({message:next.text})
		setTimeout(() => {
			window.f7.toast.close();
			queue.shift();
			if (queue.length) this.handleQueue();
		}, next.time || 2000);
	};
})();
window.toasts = toasts;

export default toasts;
