import React, { Component } from 'react';
import * as f7 from 'framework7-react';
import './Button.css';
import _ from 'lodash';

export default class Button extends Component {
	getClasses = () => {
		return (
			(this.props.className || '') +
			this.getCenterClass() +
			' g-button ' +
			this.getColor() +
			' ' +
			this.getBackground() +
			' ' +
			this.getDisabled() +
			' ' +
			this.getNoShadow()
		);
	};

	getNoShadow = () => {
		if (this.props.noShadow) return 'no-shadow';
		return '';
	};
	getCenterClass = () => {
		if (this.props.center) {
			return ' center ';
		}
		return ' ';
	};

	getColor = () => {
		if (this.props.color) {
			return 'g-' + this.props.color;
		}
		return '';
	};

	getBackground = () => {
		if (this.props.bg) {
			return 'bg-' + this.props.bg;
		}
		return '';
	};

	getDisabled = () => {
		if (this.props.disabled) {
			return 'disabled';
		}
		return '';
	};

	onClick = _.debounce(() => {
		if (this.props.disabled) return;
		else if (this.props.onClick) {
			this.props.onClick();
		}
	}, 300);

	render() {
		// eslint-disable-next-line
		let { className, color, bg, onClick, ...props } = { ...this.props };
		return (
			<f7.Button onClick={this.onClick} className={this.getClasses()} {...props}>
				{this.props.children}
			</f7.Button>
		);
	}
}
