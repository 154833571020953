import React, { Component } from 'react';
import _ from 'lodash';

export class PopupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			components: []
		};
		this.closing = false;
	}

	show = (component, props) => {
		if (this.closing) {
			setTimeout(() => {
				this.show(component, props);
			}, 450);
			return;
		}
		if (!props) props = {};
		let components = this.state.components;
		props.opened = true;
		components.push({ component, props });
		this.setState({ components });
	};

	_matchComponent = (component1, component2) => {
		if (!component1) return false;
		if (!component2) return false;
		if (component1 === component2) return true;
		if (component1 === component2.constructor) return true;
		return false;
	};

	hideAll = () => {
		let state = _.cloneDeep(this.state);
		for (let i in this.state.components) {
			state.components[i].props.opened = false;
		}
		this.closing = true;
		setTimeout(() => {
			this.setState({ components: [] }, () => {
				this.closing = false;
			});
		}, 400);
		this.setState(state);
	};

	hide = (component) => {
		let state = _.cloneDeep(this.state);
		for (let i in state.components) {
			if (this._matchComponent(state.components[i].component, component)) {
				state.components[i].props.opened = false;
				this.closing = true;
				setTimeout(() => {
					delete state.components[i];
					state.components.splice(i, 1);
					this.setState(state, () => {
						this.closing = false;
					});
				}, 400);
			}
		}
		this.setState(state);
	};

	_components = () => {
		let comps = [];
		for (let i in this.state.components) {
			let component = this.state.components[i];
			let props = this.state.components[i].props;
			let hide = this.hide;
			if (props.disableClose) {
				hide = null;
			}
			comps.push(<component.component onPopupClosed={hide} key={i} onClose={hide} {...props} />);
		}
		if (comps.length > 0) return comps;
		return null;
	};

	render() {
		return this._components();
	}
}
