import models from '../model/models';
import mainAPI from '../api/MainAPI';
import boxScannedPopupModel from '../components/popups/boxScannedPopup/BoxScannedPopup.model.js';
import toasts from '../utils/toasts';
import BoxActionsPopup from '../components/popups/boxActionsPopup/BoxActionsPopup';
import SelectUnitPopup from '../components/popups/selectUnitPopup/SelectUnitPopup';
import scanViewController from './scanViewController';
import inventoryCheckController from './inventoryCheckController';
import resealController from './resealController';

let BoxScanController = function () {
	this.start = () => {
		this.showScanner();
	};
	this.showScanner = () => {
		scanViewController.showScanner('Scan a Box', 'Place the scan area on the code', this._handleScan);
	};

	this._handleScan = async (text) => {
		models.drug.refreshData();
		let box = await mainAPI.drug_box.find_by_barcode({ barcode_id: text });
		if (box) {
			this.foundBox(box);
		} else {
			toasts.show('box not found');
		}
	};

	this.foundBox = (box) => {
		window.popupController.show(BoxActionsPopup, {
			handleDailyCheck: () => {
				this.dailyCheck(box);
				this.closeBoxActionsPopup();
			},
			handleTransfer: () => {
				this.transferBox(box);
				this.closeBoxActionsPopup();
			},
			handleReseal: () => {
				resealController.foundBox(box, null, null, null, true);
				this.closeBoxActionsPopup();
			}
		});
	};

	this.closeBoxActionsPopup = () => {
		window.popupController.hide(BoxActionsPopup);
	};

	this.dailyCheck = (box) => {
		inventoryCheckController.foundBox(box, null);
	};

	this.transferBox = (box) => {
		window.popupController.show(SelectUnitPopup, {
			completeTransfer: async (options) => {
				return await this.completeTransfer(options, box);
			}
		});
		scanViewController.hideScanner();
	};

	this.completeTransfer = async (options, box) => {
		let drug_box = {
			unit_id: options.unit_id,
			id: box.id,
			witness_id: options.witness_id,
			transfer_lat: options.transfer_lat,
			transfer_lon: options.transfer_lon,
			witness_biometric_guid: options.witness_biometric_guid,
			guest_verify__name: options.guest_verify__name,
			guest_verify__signature_img: options.guest_verify__signature_img,
			guest_verify__license_number: options.guest_verify__license_number,
			guest_verify__title: options.guest_verify__title
		};
		let res = await models.drug_box.save(drug_box);
		if (res) {
			toasts.show('transferred to new unit');
		}
		return !!res;
	};
};

let boxScanController = new BoxScanController();
export default boxScanController;
