import React from 'react';
import './ScannerInput.css';
import Input from '../input/Input';
import Label from '../label/Label';
import scanViewController from '../../controllers/scanViewController';

export default class ScannerInput extends Input {
	_showScanner = () => {
		scanViewController.showScanner('Scan Code', 'Focus the camera on the code', this._handleScanSuccess);
	};

	_handleScanSuccess = (value) => {
		scanViewController.hideScanner();
		this.setState({ value }, () => {
			if (this.props.onChange) {
				this.props.onChange(value);
			}
		});
	};

	_renderPlaceholder = () => {
		if (this.state.value) return null;
		return <Label className="placeholder">{this.props.placeholder}</Label>;
	};

	_renderValue = () => {
		if (!this.state.value) return null;
		return this.state.value;
	};

	render() {
		return (
			<div className="g-scanner-input" onClick={this._showScanner}>
				{this._renderPlaceholder()}
				{this._renderValue()}
				{this._isInvalidText()}
			</div>
		);
	}
}
