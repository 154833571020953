import DrugScanned from '../components/popups/drugScanned/DrugScanned';
import models from '../model/models';
import mainAPI from '../api/MainAPI';
import NewDrugPopup from '../components/popups/newDrugPopup/NewDrugPopup';
import verifyController from './verifyController';
import utils from '../utils/utils';
import toasts from '../utils/toasts';
import _ from 'lodash';
import getLocation from '../utils/getLocation.js';
import { getLastLocation } from '../utils/getLocation';
import objUtils from '../utils/objUtils';
import scanViewController from './scanViewController';

let DrugScanController = function () {
	this.start = (drug) => {
    this.creatingDrugs = false;
		this.currentDrug = {};
		this.drugs = [];
		if (drug) return;
		this.showScanner();
		getLocation();
	};
	this.showScanner = () => {
		scanViewController.showScanner(
			'Scan a Drug',
			'Place the scan area on the code',
			this._handleScan,
			this._showNewDrugPopup
		);
	};

	this._handleScan = async (text) => {
		let drug = await mainAPI.drug.find_by_barcode({ barcode_id: text });
		if (drug) {
      if(this.creatingDrugs && this.drugs?.length > 0){
        const message = `This code has already been used ${text}. Please use another code.`;
        mainAPI.error_log.create({type:'scannedExitingDrugWhileCreatingDrugs', message})
        return toasts.show(message, 3000);
      }
			return this.foundDrug(drug);
		}
		this.drugNotFound(text);
	};

	this.drugNotFound = (text) => {
    if(this.drugs?.length == 0){
      this.creatingDrugs = true;
    }
		let user = models.user.getCurrent();
		let user_group = models.user_group.obj();
		if (user_group?.allow_users_to_create_drugs || user.role === 'admin') {
			this.currentDrug.barcode_id = text;
			// this._showNewDrugPopup(this.currentDrug);
			this._onAddAnother(this.currentDrug);
		} else {
			toasts.show('Drug not found, You are not allowed to create drugs.');
		}
	};

	this.foundDrug = (drug) => {
		scanViewController.hideScanner();
		window.popupController.show(DrugScanned, { value: drug, onCancel: this.start });
	};

	this._onAddAnother = (drug) => {
		this.drugs.push(drug);
		let drugs = objUtils.to_obj(this.drugs, 'barcode_id');
		this.drugs = objUtils.to_array(drugs);
		scanViewController.setDrugs(this.drugs, 'Create');
		this.currentDrug = _.cloneDeep(drug);
		this.showScanner();
	};

	this._showNewDrugPopup = (drug, drugBarcodes) => {
		window.popupController.show(NewDrugPopup, {
			value: drug,
			drugBarcodes,
			onAddAnother: this._onAddAnother,
			onDoneCreating: this._onDoneCreating
		});
		scanViewController.hideScanner();
	};

	this._onDoneCreating = (drug, drugBarcodes) => {
		// this.drugs.push(drug);
		this.updateDrugs(drug, drugBarcodes);
		verifyController.start(async (verify_obj) => {
			await this._createDrugs(verify_obj);
		});
	};
	this.updateDrugs = (drug, drugBarcodes) => {
		for (let i in this.drugs) {
			for (let j in drug) {
				if (j === 'barcode_id') continue;
				this.drugs[i][j] = drug[j];
			}
		}
	};

	this._createDrugs = async (verify_obj) => {
		let promises = [];
		for (let i in this.drugs) {
			let drug = this.drugs[i];
			drug.witness_id = verify_obj.witness_id;
			drug.witness_biometric_guid = verify_obj.guid;
			drug.guest_verify__signature_img = verify_obj.guest_verify__signature_img;
			drug.guest_verify__name = verify_obj.guest_verify__name;
			drug.guest_verify__license_number = verify_obj.guest_verify__license_number;
			drug.guest_verify__title = verify_obj.guest_verify__title;
			promises.push(this._createDrug(drug));
		}
		const type = this.drugs[0].type;
		const drugType = models.drug_type.obj(type) || {};
		if (this.drugs.length == 1) {
			toasts.show('Created a vial of ' + drugType.name || type);
		} else {
			toasts.show('Created ' + this.drugs.length + ' vials of ' + drugType.name || type);
		}
		window.popupController.hideAll();
		await Promise.all(promises);
		models.drug.refreshData();
	};

	this._createDrug = async (drug) => {
		drug.expiration_date = utils.client_to_server_date(drug.expiration_date);
		let location = getLastLocation();
		drug.lat = location.lat;
		drug.lon = location.lon;
		let res = await mainAPI.drug.create(drug);
		if (!res) return;
	};
};

let drugScanController = new DrugScanController();
export default drugScanController;
